import React from "react";
import { BotMessageSquare as Icon } from "lucide-react";

const BotMessageSquare:React.FC = ()=>{
    return <>
    <div className="p-2 shadow-md rounded-xl bg-gray-100 ">
        <Icon/>
    </div>
    </>
}

export default BotMessageSquare;