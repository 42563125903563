import React, { useEffect } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import ChatLogin from "../chat/ChatLogin";
import ChatSignup from "../chat/ChatSignup";
import ChatForgotPassword from "../chat/ChatForgotPassword";
import { useNavigate, useLocation } from "react-router-dom";

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Handle URL path changes and set the correct tab value
    const path = location.pathname;
    if (path === "/signup" || path === "/forgotpassword" || path === "/login") {
      // Do nothing if we're already on the correct path
      return;
    }
    if (path.includes("/signup")) {
      // navigate("/signup", { replace: true });
      history.pushState(null, "", "/signup");
    } else if (path.includes("/forgotpassword")) {
      // navigate("/forgotpassword", { replace: true });
      history.pushState(null, "", "/forgotpassword");
    } else {
      // navigate("/login", { replace: true });
      history.pushState(null, "", "/login");
    }
  }, [location.pathname, navigate]);

  const handleTabChange = (value: string) => {
    // Push the correct route when a tab is changed
    if (value === "signup") {
      // navigate("/signup");
      history.pushState(null, "", "/signup");
    } else if (value === "forgot") {
      // navigate("/forgotpassword");
      history.pushState(null, "", "/forgotpassword");
    } else {
      // navigate("/login");
      history.pushState(null, "", "/login");
    }
  };

  return (
    <div className="w-screen bg-white text-black mt-40 flex justify-center px-4">
      <Tabs
        defaultValue={
          location.pathname.includes("/signup")
            ? "signup"
            : location.pathname.includes("/forgotpassword")
              ? "forgot"
              : "login"
        }
        onValueChange={handleTabChange}
        className="m-5 items-center"
        style={{ minWidth: 350 }}
      >
        <TabsList>
          <TabsTrigger value="login">Login</TabsTrigger>
          <TabsTrigger value="signup">Signup</TabsTrigger>
          <TabsTrigger value="forgot">Forgot Password</TabsTrigger>
        </TabsList>
        <TabsContent value="login">
          <ChatLogin />
        </TabsContent>
        <TabsContent value="signup">
          <ChatSignup />
        </TabsContent>
        <TabsContent value="forgot">
          <ChatForgotPassword />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default LoginForm;
