import React from "react";
import { MessagesSquare } from "lucide-react";
import AddUserModal from "./AddUserModal";
import { toast, Toaster } from "sonner";

interface ChatHeadingProps {
  title: string;
}

const ChatHeading: React.FC<ChatHeadingProps> = ({ title }) => {
  return (
    <>
      <div className="p-4">
        <div className="flex flex-row justify-between text-xl font-bold text-center">
          <div className="flex flex-row items-center">
            <MessagesSquare className="inline mr-4" />
            <span>{title}</span>
          </div>
          <AddUserModal
            onSubmit={() =>
              toast.success("Users updated for this conversation.")
            }
          />
        </div>
      </div>
      <Toaster position="top-right" richColors />
    </>
  );
};

export default ChatHeading;
