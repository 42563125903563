import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import DocumentPreview from "@/components/chat/DocumentPreview";

interface LocationState {
  version_id: string;
  fileId: string;
}

const MPUDPreview: React.FC = () => {
  const { fileId } = useParams<{ fileId: string }>();
  const location = useLocation();
  const [previewState, setPreviewState] = useState<LocationState | null>(null);

  useEffect(() => {
    if (location.state) {
      const { version_id, fileId } = location.state as LocationState;
      setPreviewState({version_id, fileId });
    } else {
      // If state is not available, you might want to fetch it based on fileId
      // For now, we'll just log an error
      console.error(
        "No state available. Consider fetching data based on fileId."
      );
    }
  }, [location, fileId]);

  if (!previewState) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-3/4 h-full p-4">
      <DocumentPreview
        variant="pdfViewer"
        fileId={fileId}
        version_id={previewState.version_id}
      />
    </div>
  );
};

export default MPUDPreview;
