import React, { forwardRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles

interface QuillEditorProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

const QuillEditor = forwardRef<ReactQuill, QuillEditorProps>(
  ({ value, onChange, className }, ref) => {
    return (
      <ReactQuill
        ref={ref}
        value={value}
        onChange={onChange}
        className={className}
        theme="snow" // or "bubble" based on your preference
      />
    );
  }
);

QuillEditor.displayName = "QuillEditor";
export default QuillEditor;
