import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { User, Settings, Database } from "lucide-react";
import { KnowledgeBase, PersonalSettings } from "@/components/settings";
import UserManagement from "@/components/settings/UserManagement";
import { decrypt, getCookie } from "@/utils/auth";

interface UserRoles {
  isSuperAdmin: boolean;
  isAdminOrDrafter: boolean;
}

const checkUserRoles = async (): Promise<UserRoles> => {
  const token = await getCookie("idToken");
  if (token) {
    const userClaims = await decrypt(token);
    const role = userClaims["custom:role"];
    return {
      isSuperAdmin: role.includes("superadmin"),
      isAdminOrDrafter: ["admin", "drafter"].some((r) => role.includes(r)),
    };
  }
  return { isSuperAdmin: false, isAdminOrDrafter: false };
};

const SettingsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [userRoles, setUserRoles] = useState<UserRoles | null>(null);

  useEffect(() => {
    const fetchUserRoles = async () => {
      const roles = await checkUserRoles();
      setUserRoles(roles);
    };

    fetchUserRoles();
  }, []);

  useEffect(() => {
    if (userRoles) {
      const section = searchParams.get("section");
      if (
        !section ||
        (section === "users" && !userRoles.isSuperAdmin) ||
        (section === "knowledge" &&
          !userRoles.isAdminOrDrafter &&
          !userRoles.isSuperAdmin)
      ) {
        setSearchParams({ section: "personal" });
      }
    }
  }, [userRoles, searchParams, setSearchParams]);

  const handleTabChange = (value: string) => {
    setSearchParams({ section: value });
  };

  if (userRoles === null) {
    return <div>Loading...</div>;
  }

  const activeTab = searchParams.get("section") || "personal";

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Settings</h1>
      <Tabs value={activeTab} onValueChange={handleTabChange}>
        <TabsList className="grid w-full grid-cols-3">
          <TabsTrigger value="personal" className="flex items-center">
            <Settings className="w-4 h-4 mr-2" />
            Personal
          </TabsTrigger>
          {userRoles.isSuperAdmin && (
            <TabsTrigger value="users" className="flex items-center">
              <User className="w-4 h-4 mr-2" />
              Manage Users
            </TabsTrigger>
          )}
          {(userRoles.isAdminOrDrafter || userRoles.isSuperAdmin) && (
            <TabsTrigger value="knowledge" className="flex items-center">
              <Database className="w-4 h-4 mr-2" />
              Knowledge Base
            </TabsTrigger>
          )}
        </TabsList>
        <TabsContent value="personal">
          <Card>
            <CardHeader>
              <CardTitle>Personal Settings</CardTitle>
              <CardDescription>
                Manage your personal settings here.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <PersonalSettings />
            </CardContent>
          </Card>
        </TabsContent>
        {userRoles.isSuperAdmin && (
          <TabsContent value="users">
            <Card>
              <CardHeader>
                <CardTitle>Manage Users</CardTitle>
                <CardDescription>
                  Manage user accounts and permissions.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <UserManagement />
              </CardContent>
            </Card>
          </TabsContent>
        )}
        {(userRoles.isAdminOrDrafter || userRoles.isSuperAdmin) && (
          <TabsContent value="knowledge">
            <Card>
              <CardHeader>
                <CardTitle>Knowledge Base</CardTitle>
                <CardDescription>
                  Manage the knowledge base content.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <KnowledgeBase />
              </CardContent>
            </Card>
          </TabsContent>
        )}
      </Tabs>
    </div>
  );
};

export default SettingsPage;
