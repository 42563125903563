import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
  PaginationLink,
} from "@/components/ui/pagination";
import { Search } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { toast } from "sonner";
import { fetchUsers, updateRole } from "@/utils/api";

type Role = "superadmin" | "admin" | "drafter" | "enforcer";

interface User {
  username: string;
  name: string; // New field for user's name
  email: string;
  role: Role;
  county: string;
}

interface UserResponse {
  users: User[];
  page: number;
  page_size: number;
  total: number;
  has_more: boolean;
}

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const fetchUserData = useCallback(async () => {
    setLoading(true);
    try {
      const response: UserResponse = await fetchUsers(page, pageSize);
      setUsers(response.users);
      setTotalUsers(response.total);
      setPage(response.page);
      setPageSize(response.page_size);
      setHasMore(response.has_more);
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.error("Failed to fetch users");
    } finally {
      setLoading(false);
    }
  }, [page, pageSize]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleSearch = () => {
    setPage(1);
    fetchUserData();
  };

  const handlePrevPage = () => {
    setPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    if (hasMore) {
      setPage((prev) => prev + 1);
    }
  };

  const handleRoleChange = async (
    username: string,
    name: string,
    newRole: Role
  ) => {
    try {
      await updateRole(username, newRole);
      toast.success(`Changed role for ${name} to ${newRole}`);
      setUsers(
        users.map((user) =>
          user.username === username ? { ...user, role: newRole } : user
        )
      );
    } catch (error) {
      console.error("Error updating role:", error);
      toast.error("Failed to update role");
    }
  };

  const isPrevDisabled = page === 1 || loading;
  const isNextDisabled = !hasMore || loading;

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">Cognito Users</h1>

      <div className="flex mb-4">
        <Input
          placeholder="Search users..."
          className="max-w-sm mr-2"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button onClick={handleSearch}>
          <Search className="h-4 w-4 mr-2" />
          Search
        </Button>
      </div>

      <div className="border rounded-md">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[200px]">Name</TableHead>
              <TableHead className="w-[220px]">Email</TableHead>
              <TableHead>Role</TableHead>
            </TableRow>
          </TableHeader>
        </Table>
        <div className="max-h-[400px] overflow-auto">
          <Table>
            <TableBody>
              {loading ? (
                Array.from({ length: pageSize }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell className="w-[200px]">
                      <div className="h-5 bg-gray-200 rounded animate-pulse"></div>
                    </TableCell>
                    <TableCell className="w-[180px]">
                      <div className="h-5 bg-gray-200 rounded animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
                    </TableCell>
                  </TableRow>
                ))
              ) : users.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={4} className="h-[352px] text-center">
                    No users found
                  </TableCell>
                </TableRow>
              ) : (
                users.map((user) => (
                  <TableRow key={user.username}>
                    <TableCell className="w-[200px]">
                      <Link
                        to={`/user/${user.username}`}
                        className="text-blue-600 hover:underline"
                      >
                        {user.name}
                      </Link>
                    </TableCell>
                    <TableCell className="w-[200px]">{user.email}</TableCell>
                    <TableCell>
                      <Select
                        value={user.role}
                        onValueChange={(value: Role) =>
                          handleRoleChange(user.username, user.name, value)
                        }
                        disabled={user.role === "superadmin"}
                      >
                        <SelectTrigger
                          // className={`w-[180px] ${user.role === "superadmin" ? "opacity-50 cursor-not-allowed" : ""}`}
                          className="w-[200px]"
                        >
                          <SelectValue placeholder="Select a role" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="superadmin">
                            Super Admin
                          </SelectItem>
                          <SelectItem value="admin">Admin</SelectItem>
                          <SelectItem value="drafter">Drafter</SelectItem>
                          <SelectItem value="enforcer">Enforcer</SelectItem>
                        </SelectContent>
                      </Select>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>
      </div>

      <Pagination className="mt-4">
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious
              onClick={(e) => {
                e.preventDefault();
                if (!isPrevDisabled) handlePrevPage();
              }}
              aria-disabled={isPrevDisabled}
              className={isPrevDisabled ? "pointer-events-none opacity-50" : ""}
            />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink>{page}</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationNext
              onClick={(e) => {
                e.preventDefault();
                if (!isNextDisabled) handleNextPage();
              }}
              aria-disabled={isNextDisabled}
              className={isNextDisabled ? "pointer-events-none opacity-50" : ""}
            />
          </PaginationItem>
        </PaginationContent>
      </Pagination>

      <div className="mt-2 text-sm text-gray-500">
        Total Users: {totalUsers}
      </div>
    </div>
  );
};

export default UserManagement;
