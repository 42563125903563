import React, { useState, useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Chat, MPUDVersionHistory } from "@/utils/api";
import {
  MessageCircle,
  Settings,
  HelpCircle,
  LogOut,
  MessageSquarePlusIcon,
  PanelLeftClose,
  PanelLeftOpen,
  Files,
  ChevronUp,
  ChevronDown,
} from "lucide-react";
import { fetchUserData, removeCookie } from "@/utils/auth";
import { fetchAllChats } from "@/utils/api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

interface ChatItem {
  id: string;
  name: string;
}

interface MPUDVersions {
  _id: string;
  version: string;
  S3Location: string;
  created_dateTime: string;
}
interface MPUDVersion {
  S3Location: string;
  version: string;
  message: string;
  fileData: string;
  versions: MPUDVersions[];
}

interface SidebarProps {
  recentChats: ChatItem[];
  onNewChat: () => void;
  onChatSelect: (chatId: string, chatTitle: string) => void;
  onMPUDVersionSelect: (fileId: string, S3Location: string) => void;
  sendSelectedChatTitle: (title: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  onNewChat,
  onChatSelect,
  onMPUDVersionSelect,
  sendSelectedChatTitle,
}) => {
  const { chatId } = useParams<{ chatId?: string }>();

  const [isOpen, setIsOpen] = useState(false);
  const [isPinned, setIsPinned] = useState(true);
  const [chatsList, setChatsList] = useState<Chat[]>([]);
  const [activeChatId, setActiveChatId] = useState<string>("");
  const [countyName, setCountyName] = useState<string>("");
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [recentChatsOpen, setRecentChatsOpen] = useState(true);
  const [mpudDocsOpen, setMpudDocsOpen] = useState(false);
  const [MPUDDocumentVersions, setMPUDDocumentVersions] =
    useState<MPUDVersion>();
  const navigate = useNavigate();

  // Static values for county and user_id
  // const county = "pasco";
  // const user_id = "anjan";

  useEffect(() => {
    const loadCounty = async () => {
      const userData = await fetchUserData();
      if (userData) {
        const countyWithState = userData.county;
        const countyName = countyWithState.split(",")[0] || "Pasco County";
        setCountyName(countyName);
      }
    };

    loadCounty();
  }, []);
  useEffect(() => {
    const handleMouseEnter = () => !isPinned && setIsOpen(true);
    const handleMouseLeave = () => !isPinned && setIsOpen(false);

    const sidebarElement = sidebarRef.current;
    if (sidebarElement) {
      sidebarElement.addEventListener("mouseenter", handleMouseEnter);
      sidebarElement.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (sidebarElement) {
        sidebarElement.removeEventListener("mouseenter", handleMouseEnter);
        sidebarElement.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, [isPinned]);

  const setChatListing = async () => {
    const chats = await fetchAllChats();
    setChatsList(chats.chats.reverse());
    if (chatId) {
      setActiveChatId(chatId);
      const thisChat = chats.chats.find((chat) => chat.chat_id == chatId);
      if (thisChat) sendSelectedChatTitle(thisChat.title);
    }
  };

  useEffect(() => {
    const fetchMPUDVersions = async () => {
      try {
        const versions = await MPUDVersionHistory(countyName);
        setMPUDDocumentVersions(versions as MPUDVersion);
      } catch (error) {
        console.error("Failed to fetch MPUD versions:", error);
      }
    };

    fetchMPUDVersions();
    setChatListing();
  }, [chatId, countyName]);

  useEffect(() => {
    setChatListing();
  }, [chatId]);

  const togglePin = () => {
    setIsPinned(!isPinned);
    setIsOpen(!isPinned);
  };

  const toggleRecentChats = () => setRecentChatsOpen(!recentChatsOpen);
  const toggleMpudDocs = () => setMpudDocsOpen(!mpudDocsOpen);

  const handleSettings = () => {
    navigate("/settings");
  };

  const onLogout = async () => {
    await removeCookie("idToken");
    await removeCookie("refreshToken");
    await removeCookie("userId");
    await removeCookie("username");
    await removeCookie("email");
    toast.success("Logged out successfully!");
    navigate("/login");
  };

  return (
    <nav className="z-20  max-md:pointer-events-none max-md:fixed">
      <div
        ref={sidebarRef}
        className={`h-screen flex flex-col transition-all duration-300 ease-in-out 
        ${
          isOpen || isPinned
            ? "w-64 bg-white/30 backdrop-blur-md shadow-lg border-r border-white/20"
            : "w-20 bg-transparent"
        }`}
      >
        <div className="p-4 flex items-center justify-between">
          <div className="-mr-1 flex translate-y-px items-center">
            <div className="ml-px flex h-9 flex-1 items-center pb-1 leading-none max-md:pointer-events-none max-md:opacity-0">
              <h1 className="text-xl font-bold whitespace-nowrap overflow-hidden text-ellipsis">
                {countyName}
              </h1>
            </div>
          </div>
          {(isOpen || isPinned) &&
            (isPinned ? (
              <PanelLeftClose
                className="h-6 w-6 cursor-pointer flex-shrink-0"
                onClick={togglePin}
              />
            ) : (
              <PanelLeftOpen
                className="h-6 w-6 cursor-pointer flex-shrink-0"
                onClick={togglePin}
              />
            ))}
        </div>
        {/* <Separator /> */}
        <ScrollArea className="flex-grow">
          <div className="p-4 space-y-2">
            {(isOpen || isPinned) && (
              <>
                <Button
                  variant="ghost"
                  className="w-full gap-2 justify-start"
                  onClick={onNewChat}
                >
                  <MessageSquarePlusIcon className="h-4 w-4" />
                  <span className="ml-2">New Chat</span>
                </Button>
                <div className="space-y-1">
                  <Button
                    variant="ghost"
                    className="w-full gap-2 justify-between"
                    onClick={toggleRecentChats}
                  >
                    <span className="text-sm text-zinc-600 font-medium">
                      Recent Chats
                    </span>
                    {recentChatsOpen ? (
                      <ChevronUp className="h-4 w-4" />
                    ) : (
                      <ChevronDown className="h-4 w-4" />
                    )}
                  </Button>
                  {recentChatsOpen && (
                    <>
                      {chatsList.length > 0 ? (
                        chatsList.map((chat: Chat) => (
                          <Button
                            key={chat.chat_id}
                            variant="ghost"
                            className={`w-full justify-start ${
                              activeChatId == chat.chat_id ? "bg-zinc-200" : ""
                            }`}
                            onClick={() => {
                              onChatSelect(chat.chat_id, chat.title);
                              setActiveChatId(chat.chat_id);
                            }}
                          >
                            <MessageCircle className="h-4 w-4" />
                            <span className="ml-2 truncate">{chat.title}</span>
                          </Button>
                        ))
                      ) : (
                        <div className="p-4 border border-dashed rounded-md">
                          <p className="text-sm text-gray-600">
                            No chats yet. Start a new conversation!
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="space-y-1">
                  <Button
                    variant="ghost"
                    className="w-62 gap-2 justify-between"
                    onClick={toggleMpudDocs}
                  >
                    <span className="text-sm text-zinc-600 font-medium">
                      MPUD Documents Version
                    </span>
                    {mpudDocsOpen ? (
                      <ChevronUp className="h-4 w-4" />
                    ) : (
                      <ChevronDown className="h-4 w-4" />
                    )}
                  </Button>
                  {mpudDocsOpen && (
                    <>
                      {(MPUDDocumentVersions?.versions ?? []).length > 0 ? (
                        MPUDDocumentVersions?.versions.map(
                          (MPUDDocument, index) => (
                            <Button
                              key={index}
                              variant="ghost"
                              className="w-full justify-start"
                              onClick={() =>
                                onMPUDVersionSelect(
                                  MPUDDocument._id,
                                  encodeURIComponent(MPUDDocument.version)
                                )
                              }
                            >
                              <Files className="h-4 w-4" />
                              <span className="ml-2 truncate">
                                {MPUDDocument.version}
                              </span>
                            </Button>
                          )
                        )
                      ) : (
                        <div className="p-4 border border-dashed rounded-md">
                          <p className="text-sm text-gray-600">
                            No documents yet. Start a new conversation & create
                            one!
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </ScrollArea>
        {/* <Separator /> */}
        <div className="p-4 space-y-2">
          <Button
            onClick={handleSettings}
            variant="ghost"
            className="w-full justify-start"
          >
            <Settings className="h-4 w-4" />
            {(isOpen || isPinned) && <span className="ml-2">Settings</span>}
          </Button>
          <Button variant="ghost" className="w-full justify-start">
            <HelpCircle className="h-4 w-4" />
            {(isOpen || isPinned) && <span className="ml-2">Help</span>}
          </Button>
          <Button
            variant="ghost"
            className="w-full justify-start text-red-500 hover:text-red-500/90 hover:bg-red-100"
            onClick={onLogout}
          >
            <LogOut className="h-4 w-4" />
            {(isOpen || isPinned) && <span className="ml-2">Log out</span>}
          </Button>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
