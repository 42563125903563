import React, { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { UserPlus, X, Loader } from "lucide-react";
import {
  getAllUsers,
  toggleUsersFromChat,
  ChatUserElement,
} from "src/utils/api";
import * as Separator from "@radix-ui/react-separator";
import { getCookie } from "@/utils/auth";
import { useParams } from "react-router-dom";

interface ModalProps {
  onSubmit: () => void;
}

interface UserElementProps {
  user: ChatUserElement;
  index: number;
  totalUsers: number;
  onUserSelect: (user: ChatUserElement) => void;
}

const UserElement: React.FC<UserElementProps> = ({
  user,
  index,
  totalUsers,
  onUserSelect,
}) => {
  const onSelectChange = (checked: boolean) => {
    const updatedUser = user;
    updatedUser.selected = checked;
    onUserSelect(updatedUser);
  };

  return (
    <>
      <div
        key={user.sub}
        className="flex flex-row justify-between items-center cursor-pointer max-h-25 w-full hover:bg-slate-100 px-5 py-4"
        onClick={() => onSelectChange(!user.selected)}
      >
        <div>
          <p className="font-weight-bold text-m">{user.email}</p>
          <p className="text-xs text-slate-500">{user.user_name}</p>
        </div>
        <input
          id="default-checkbox"
          type="checkbox"
          checked={user.selected}
          onChange={(e) => onSelectChange(e.currentTarget.checked)}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      {index + 1 < totalUsers && <Separator.Root className="bg-slate-200" />}
    </>
  );
};

const AddUserModal: React.FC<ModalProps> = ({ onSubmit }) => {
  const [users, setUsers] = useState<ChatUserElement[]>([]);

  const [initialChatUsers, setInitialChatUsers] = useState<{
    [key: string]: boolean;
  }>({});
  const [updatedChatUsers, setUpdatedChatUsers] = useState<{
    [key: string]: boolean;
  }>({});

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { chatId } = useParams<{ chatId?: string }>();

  const fetchUsers = async () => {
    const currentUserId = await getCookie("userId");
    setIsLoading(true);
    const usersResponse = await getAllUsers(chatId);

    setUsers(usersResponse.users.filter((u) => u.user_name != currentUserId));
    usersResponse.users.forEach((u) => {
      setInitialChatUsers((prev) => ({ ...prev, [u.user_name]: u.selected }));
    });
    setIsLoading(false);
  };

  const onUserSelect = (user: ChatUserElement) => {
    setUsers((prevUsers) => {
      return prevUsers.map((u) => {
        if (u.user_name === user.user_name) {
          const isInitiallySelected = initialChatUsers[user.user_name];
          setUpdatedChatUsers((prev) => {
            if (isInitiallySelected === user.selected) {
              const { [user.user_name]: _, ...rest } = prev;
              return rest; // Remove user if selection hasn't changed
            }
            return { ...prev, [user.user_name]: user.selected };
          });
          return { ...u, selected: user.selected };
        }
        return u;
      });
    });
  };

  const toggleChatUsers = async () => {
    setIsLoading(true);
    await toggleUsersFromChat(chatId, updatedChatUsers);
    setIsModalOpen(false);
    setIsLoading(false);
    onSubmit();
  };

  useEffect(() => {
    if (isModalOpen) fetchUsers();
  }, [isModalOpen]);

  return (
    <>
      <Dialog.Root
        open={isModalOpen}
        onOpenChange={(open: boolean) => setIsModalOpen(open)}
      >
        <Dialog.Trigger asChild>
          <button
            className="rounded-full size-12 flex items-center justify-center text-black bg-white hover:bg-slate-100 cursor-pointer outline-none"
            aria-label="Notifications"
          >
            <UserPlus />
          </button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="z-50 bg-gray-950/[0.8] data-[state=open]:animate-overlayShow fixed inset-0" />
          <Dialog.Content className="z-50 data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-full w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
            <Dialog.Title className="text-mauve12 font-bold text-m m-5 mb-0">
              Add user
            </Dialog.Title>
            <Dialog.Description className="text-mauve11 mt-1 m-5 mt-2 text-sm leading-normal">
              Add other users to this conversation.
            </Dialog.Description>
            <div className="flex flex-col items-center max-h-60 overflow-y-scroll">
              {users &&
                !isLoading &&
                users.map((user, i) => {
                  return (
                    <UserElement
                      index={i}
                      totalUsers={users.length}
                      user={user}
                      onUserSelect={onUserSelect}
                    />
                  );
                })}
              {isLoading && (
                <Loader className="rotating m-5 text-gray-500 size-12" />
              )}
            </div>

            <div className="m-5 flex justify-end">
              <button
                className="bg-gray-950 hover:bg-gray-900 px-4 py-2 text-gray-50 rounded-lg disabled:bg-gray-500 disabled:cursor-not-allowed"
                disabled={isLoading}
                onClick={toggleChatUsers}
              >
                Add Selected Users
              </button>
            </div>
            <Dialog.Close asChild>
              <button
                className="fixed top-5 right-5 inline-flex size-5 appearance-none items-center justify-center rounded-full focus:outline-none"
                aria-label="Close"
              >
                <X />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default AddUserModal;
