import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Chat from "@/pages/chat/";
import MainLayout from "@/layout/MainLayout";
import ChatLayout from "@/layout/ChatLayout";
import ChatWithID from "@/pages/chat/[chatId]";
import LoginForm from "./components/auth/LoginForm";
import { Toaster } from "@/components/ui/sonner";
import MPUDPreview from "@/pages/MPUDPreview";
import SettingsPage from "@/pages/Settings";
import SettingsLayout from "@/layout/SettingsLayout";
import ProtectedRoute from "@/components/auth/ProtectedRoute";

function App() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/signup" element={<LoginForm />} />
        <Route path="/forgotpassword" element={<LoginForm />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Navigate to="/chat" replace />} />
          <Route path="/chat" element={<ChatLayout />}>
            <Route index element={<Chat />} />
            <Route path=":chatId" element={<ChatWithID />} />
          </Route>
          <Route path="/MPUDPreview" element={<ChatLayout />}>
            <Route path=":fileId" element={<MPUDPreview />} />
          </Route>
          <Route path="/settings" element={<SettingsLayout />}>
            <Route index element={<SettingsPage />} />
          </Route>
        </Route>
      </Routes>
      <Toaster position="top-right" richColors />
    </>
  );
}

export default App;
