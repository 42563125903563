import { decrypt, setCookie, getCookie } from "./auth";
import { fetchEventSource } from "@microsoft/fetch-event-source";

export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export const getHeaders = async () => {
  const idToken = await getCookie("idToken");
  const headers = new Headers({
    "Content-Type": "application/json",
    Authorization: `Bearer ${idToken}`, // Use Bearer scheme if applicable
  });
  return headers;
};
type Role = "superadmin" | "admin" | "drafter" | "enforcer";

type timezone = {
  display: string;
  gmt: string;
};
export interface User {
  username: string;
  name: string;
  email: string;
  role: Role;
  county: string;
  designation?: string;
  department?: string;
  timezone?: timezone;
  profile_image?: string;
  timezoneGMT?: string;
  chatModel?: string;
}

// USER MANAGEMENT API
interface ApiResponse {
  status: number;
  error: boolean;
  message: string;
  idToken?: string;
}

interface SignUpResponse {
  [key: string]: any; // Adjust the type as per the actual response structure
}

interface ForgotPasswordResponse {
  [key: string]: any; // Adjust the type as per the actual response structure
}

interface ConfirmResponse {
  [key: string]: any; // Adjust the type as per the actual response structure
}

interface UserResponse {
  [key: string]: any; // Adjust the type as per the actual response structure
}

interface UsersResponse {
  users: User[];
  page: number;
  page_size: number;
  total: number;
  has_more: boolean;
}

export interface Chat {
  chat_id: string;
  title: string;
  user_id: string;
  created_at: string;
}

interface ChatResponse {
  total_chats: number;
  chats: Chat[];
}

interface Message {
  human: { message: string };
  ai: { message: string };
}

export interface ChatMessage {
  chat_id: string;
  content: Message;
  timestamp: string;
}

export interface NewMessageResponse {
  content: string;
  chat_id: string;
  chat_title: string;
}

interface ChatMessageResponse {
  total_conversations: number;
  messages: ChatMessage[];
}

export interface Notification {
  id: string;
  user_id: string;
  title: string;
  description: string;
  resource_url: string;
  read: boolean;
}

interface NotificationsResponse {
  total_notifications: number;
  notifications: Notification[];
}

export interface ChatUserElement {
  user_name: string;
  sub: string;
  email_verified: string;
  email: string;
  selected: boolean;
}

interface ChatUsersResponse {
  total_users: number;
  users: ChatUserElement[];
}

// Login user
export async function loginUser(
  email: string,
  password: string
): Promise<ApiResponse> {
  const url = `${BACKEND_BASE_URL}/auth/login`;

  const body = JSON.stringify({
    email: email,
    password: password,
  });

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: await getHeaders(),
      body: body,
    });

    const data = await response.json();

    if (!response.ok) {
      const errorMessage = data?.detail || "Login failed.";
      return { status: response.status, error: true, message: errorMessage };
    }

    await setCookie("idToken", data["id_token"]);
    await setCookie("refreshToken", data["refresh_token"]);
    const claims = await decrypt(data["id_token"]);
    await setCookie("userId", claims["sub"]);
    await setCookie("username", claims["name"]);
    await setCookie("email", claims["email"]);

    return {
      status: response.status,
      error: false,
      message: "Login successful.",
      idToken: data["id_token"],
    };
  } catch (error) {
    console.error("Error logging in user:", error);
    return { status: 500, error: true, message: "An error occurred." };
  }
}

// SignupUser
export async function signUpUser(
  email: string,
  password: string,
  name: string,
  county: string
): Promise<SignUpResponse> {
  const url = `${BACKEND_BASE_URL}/auth/signup`;

  const body = JSON.stringify({
    email: email,
    password: password,
    name: name,
    county: county,
  });

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: await getHeaders(),
      body: body,
    });

    if (!response.ok) {
      throw new Error(`HTTP error status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error signing up user:", error);
    throw error;
  }
}

// Forgot password
export async function forgotPasswordRequest(
  email: string
): Promise<ForgotPasswordResponse> {
  const encodedEmail = encodeURIComponent(email);
  const url = `${BACKEND_BASE_URL}/auth/forgot_password?email=${encodedEmail}`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: await getHeaders(),
    });

    if (!response.ok) {
      throw new Error(`HTTP error status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error requesting password reset:", error);
    throw error;
  }
}

// Confirm Signup
export async function confirmSignUp(
  username: string,
  confirmationCode: string
): Promise<ConfirmResponse> {
  const url = `${BACKEND_BASE_URL}/auth/confirm_signup?username=${encodeURIComponent(username)}&confirmation_code=${encodeURIComponent(confirmationCode)}`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: await getHeaders(),
      body: JSON.stringify({}),
    });

    if (!response.ok) {
      throw new Error(`HTTP error status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error confirming sign up:", error);
    throw error;
  }
}

// Confirm Forgot Password
export async function confirmForgotPassword(
  email: string,
  password: string,
  confirmationCode: string
): Promise<ConfirmResponse> {
  const url = `${BACKEND_BASE_URL}/auth/confirm_forgot_password?confirmation_code=${encodeURIComponent(confirmationCode)}`;

  const body = JSON.stringify({
    email: email,
    password: password,
  });

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: body,
    });

    if (!response.ok) {
      throw new Error(`HTTP error status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error confirming forgot password:", error);
    throw error;
  }
}

// Resend Confirmation Code
export async function resendConfirmationCode(
  username: string
): Promise<ConfirmResponse> {
  const url = `${BACKEND_BASE_URL}/auth/resend_confirmation_code?username=${encodeURIComponent(username)}`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: await getHeaders(),
      body: JSON.stringify({}),
    });

    if (!response.ok) {
      throw new Error(`HTTP error status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error resending confirmation code:", error);
    throw error;
  }
}

export const logout = async (username: string) => {
  const response = await fetch(
    `${BACKEND_BASE_URL}/auth/logout?user_id=${encodeURIComponent(username)}`,
    {
      method: "POST",
      headers: await getHeaders(),
      body: JSON.stringify({}),
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

//  Get User by ID
export const getUserById = async (userId: string): Promise<UserResponse> => {
  const response = await fetch(`${BACKEND_BASE_URL}/users/${userId}`, {
    method: "GET",
    headers: await getHeaders(),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const MPUDVersionHistory = async (
  county: string
): Promise<UserResponse> => {
  const response = await fetch(
    `${BACKEND_BASE_URL}/versionControl/get_version_for_docs?county_name=${encodeURIComponent(county)}`,
    {
      method: "GET",
      headers: await getHeaders(),
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const MPUDDocumentRulesData = async (
  chatId: string
): Promise<UserResponse> => {
  const response = await fetch(
    `${BACKEND_BASE_URL}/versionControl/get_mpud_document_preview_data?chat_id=${encodeURIComponent(chatId)}`,
    {
      method: "GET",
      headers: await getHeaders(),
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const MPUDDocumentRulesAccept = async (
  chatId: string,
  county_name: string,
  ruleIds: string
): Promise<UserResponse> => {
  const bodyAccept = JSON.stringify({
    version_data: [],
  });
  const response = await fetch(
    `${BACKEND_BASE_URL}/versionControl/post_mpud_version_data?chat_id=${encodeURIComponent(chatId)}&county_name=${county_name}&rule_ids=${ruleIds}`,
    {
      method: "POST",
      headers: await getHeaders(),
      body: bodyAccept,
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const MPUDDocumentRulesReject = async (
  ruleIds: string,
  chatId: string
): Promise<UserResponse> => {
  const response = await fetch(
    `${BACKEND_BASE_URL}/versionControl/reject_rec_in_mpud_version_data?chat_id=${chatId}&rule_ids=${ruleIds}`,
    {
      method: "PUT",
      headers: await getHeaders(),
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const MPUDDocumentRulesEdit = async (
  chatId: string,
  data: string,
  ruleIds: string
): Promise<UserResponse> => {
  const response = await fetch(
    `${BACKEND_BASE_URL}/versionControl/update_rec_in_mpud_version_data?chat_id=${chatId}&update_data=${data}&rule_id=${ruleIds}`,
    {
      method: "PATCH",
      headers: await getHeaders(),
    }
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const MPUDDocumentCreation = async (
  county: string,
  fileName: string,
  chatId: string
): Promise<UserResponse> => {
  const response = await fetch(
    `${BACKEND_BASE_URL}/versionControl/create_file_version_for_county_and_get_blob?county_name=${county}&file_name=${fileName}&chat_id=${chatId}`,
    {
      method: "POST",
      headers: await getHeaders(),
    }
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response;
};

export const MPUDVersionFile = async (
  version_id: string
): Promise<UserResponse> => {
  const response = await fetch(
    `${BACKEND_BASE_URL}/versionControl/get_file_data_for_version?mpud_versions_id=${encodeURIComponent(version_id)}`,
    {
      method: "GET",
      headers: await getHeaders(),
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response;
};

export const fetchAllChats = async (): Promise<ChatResponse> => {
  const response = await fetch(`${BACKEND_BASE_URL}/chats`, {
    method: "GET",
    headers: await getHeaders(),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const fetchUsers = async (
  Page: number,
  PageSize: number
): Promise<UsersResponse> => {
  const response = await fetch(
    `${BACKEND_BASE_URL}/users?page=${Page}&page_size=${PageSize}`,
    {
      method: "GET",
      headers: await getHeaders(),
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const updateRole = async (
  username: string,
  role: string
): Promise<UserResponse> => {
  const response = await fetch(`${BACKEND_BASE_URL}/users/${username}/role`, {
    method: "PUT",
    headers: await getHeaders(),
    body: JSON.stringify({ role: role }),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const updateUserData = async (
  username: string,
  userData: Partial<User>
): Promise<UserResponse> => {
  const response = await fetch(`${BACKEND_BASE_URL}/users/${username}`, {
    method: "PUT",
    headers: await getHeaders(),
    body: JSON.stringify(userData),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const updateUserProfileImage = async (
  username: string,
  file: File
): Promise<UserResponse> => {
  const formData = new FormData();
  formData.append("profileImage", file);

  const idToken = await getCookie("idToken");
  const headers = {
    Authorization: `Bearer ${idToken}`,
  };

  const response = await fetch(
    `${BACKEND_BASE_URL}/users/${username}/profileImage`,
    {
      method: "PUT",
      headers: headers,
      body: formData,
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const getUserProfileImage = async (
  username: string,
  size: number
): Promise<Blob> => {
  const response = await fetch(
    `${BACKEND_BASE_URL}/users/${username}/profileImage/${size}/${size}`,
    {
      method: "GET",
      headers: await getHeaders(),
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.blob();
};

export const refreshToken = async () => {
  const refreshToken = await getCookie("refreshToken");
  const response = await fetch(`${BACKEND_BASE_URL}/auth/refresh_token`, {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify({ refresh_token: refreshToken }),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  await setCookie("idToken", data["id_token"]);
  const claims = await decrypt(data["id_token"]);
  await setCookie("userId", claims["sub"]);
  await setCookie("username", claims["name"]);
  await setCookie("email", claims["email"]);
};

export const uploadFile = async (file: File): Promise<UserResponse> => {
  const formData = new FormData();
  formData.append("file", file);

  const idToken = await getCookie("idToken");
  const headers = {
    Authorization: `Bearer ${idToken}`, // Use Bearer scheme if applicable
  };

  const response = await fetch(
    `${BACKEND_BASE_URL}/ocr/upload_and_create_embedding`,
    {
      method: "POST",
      headers: headers,
      body: formData,
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const fetchChatHistory = async (
  chatId: string
): Promise<ChatMessageResponse> => {
  const response = await fetch(
    `${BACKEND_BASE_URL}/history?chat_id=${chatId}&limit=100`,
    {
      method: "GET",
      headers: await getHeaders(),
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const fetchFiles = async (): Promise<UserResponse> => {
  const response = await fetch(`${BACKEND_BASE_URL}/ocr/fetch_file_data`, {
    method: "GET",
    headers: await getHeaders(),
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

// View File
export const viewFile = async (s3Location: string): Promise<Blob> => {
  const url = `${BACKEND_BASE_URL}/ocr/view_file?s3Location=${encodeURIComponent(s3Location)}`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: await getHeaders(),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.blob();
  } catch (error) {
    console.error("Error viewing file:", error);
    throw error;
  }
};

export const sendMessage = async (
  message: string,
  setMessage: (messageObj: NewMessageResponse) => void,
  setIsTyping: (status: boolean) => any,
  chatId: string | undefined = undefined
) => {
  const idToken = await getCookie("idToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${idToken}`, // Use Bearer scheme if applicable
  };

  // TODO: make model_name dynamic
  let url = `${BACKEND_BASE_URL}/inspect?model_name=gpt-4o-mini&message=${message}`;
  if (chatId) url += `&chat_id=${chatId}`;

  const controller = new AbortController();

  const eventSource = fetchEventSource(url, {
    method: "POST",
    headers: headers,
    openWhenHidden:true,
    signal: controller.signal,
    onopen: async (res: Response) => {
      if (res.ok && res.status === 200) {
        setIsTyping(true);
        console.log("Connection made ", res);
        return Promise.resolve();
      } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
        console.log("Client-side error ", res);
      }
    },
    onmessage(event: { data: any }) {
      const parsedData: NewMessageResponse = JSON.parse(event.data);
      setMessage(parsedData);
    },
    onclose() {
      setIsTyping(false);
      console.log("Connection closed by the server");
      controller.abort();
      return Promise.resolve();
    },
    onerror(err: any) {
      setIsTyping(false);
      console.log("There was an error from server", err);
      console.error("Error:", err);
      controller.abort(); // Close the connection
    },
  });
};

export const fetchUserNotifications =
  async (): Promise<NotificationsResponse> => {
    const response = await fetch(`${BACKEND_BASE_URL}/notifications`, {
      method: "GET",
      headers: await getHeaders(),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response.json();
  };

export const markAllNotificationsAsRead =
  async (): Promise<NotificationsResponse> => {
    const response = await fetch(`${BACKEND_BASE_URL}/notification`, {
      method: "PATCH",
      headers: await getHeaders(),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response.json();
  };

export const getAllUsers = async (
  chatId: string
): Promise<ChatUsersResponse> => {
  const response = await fetch(
    `${BACKEND_BASE_URL}/chat/users?chat_id=${chatId}`,
    {
      method: "GET",
      headers: await getHeaders(),
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export async function postPage(
  file_path: string,
  page_number: number,
  source_text: string,
  width?: number | null,
  height?: number | null
): Promise<Blob> {
  const url = `${BACKEND_BASE_URL}/source/page/`;

  const body = JSON.stringify({
    file_path,
    page_number,
    source_text,
    width,
    height
  });

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: await getHeaders(),
      body: body
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.blob();
  } catch (error) {
    console.error('Error posting page:', error);
    throw error;
  }
}

export const toggleUsersFromChat = async (
  chatId: string,
  chatUsers: { [key: string]: boolean }
): Promise<ChatUsersResponse> => {
  const body = JSON.stringify(chatUsers);

  const response = await fetch(
    `${BACKEND_BASE_URL}/chats/toggle_users?chat_id=${chatId}`,
    {
      method: "POST",
      headers: await getHeaders(),
      body: body,
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};
