import React, { useEffect, useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { Bell, Loader } from "lucide-react";
import {
  fetchUserNotifications,
  markAllNotificationsAsRead,
  Notification,
} from "@/utils/api";
import * as Separator from "@radix-ui/react-separator";

interface NotificationProps {
  onMarkedAsRead: () => void;
}

const NotificationElement: React.FC<Notification> = ({
  id,
  title,
  description,
  read,
}) => {
  return (
    <div
      key={id}
      className={`max-h-25 w-full hover:bg-slate-100 px-5 py-4 ${read ? "opacity-60" : ""}`}
    >
      <p className="text-sm font-bold mb-2">{title}</p>
      <p className="text-sm line-clamp-2 break-words" title={description}>
        {description}
      </p>
    </div>
  );
};

const NotificationView: React.FC<NotificationProps> = ({ onMarkedAsRead }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const fetchNotifications = async () => {
    const notifications = await fetchUserNotifications();

    if (notifications.total_notifications)
      setNotifications(notifications.notifications);
    setIsLoading(false);
  };

  const markNotificationsAsRead = async () => {
    setIsLoading(true);
    markAllNotificationsAsRead();
    setIsLoading(false);
    onMarkedAsRead();
  };

  return (
    <>
      <Popover.Root onOpenChange={(open: boolean) => setIsPopoverOpen(open)}>
        <Popover.Trigger asChild>
          <button
            className={`${isPopoverOpen ? "bg-slate-100" : ""} m-5 rounded-full size-12 inline-flex items-center justify-center text-black hover:bg-slate-100 cursor-pointer outline-none`}
            aria-label="Notifications"
            onClick={fetchNotifications}
          >
            <Bell />
          </button>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="mx-5 rounded w-96 bg-white shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] focus:shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2),0_0_0_2px_theme(colors.violet7)] will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
            sideOffset={5}
          >
            <div className="flex flex-col items-center max-h-96 overflow-y-scroll">
              <div className="flex flex-row p-5 w-full justify-between">
                <p className="text-mauve12 font-bold text-m">
                  Notifications ({notifications.length})
                </p>
                {notifications.length ? (
                  <button
                    className="text-sm text-blue-500 hover:text-blue-800 disabled:text-blue-200"
                    disabled={isLoading}
                    onClick={markNotificationsAsRead}
                  >
                    Mark all as read
                  </button>
                ) : (
                  <></>
                )}
              </div>

              {notifications.length && !isLoading ? (
                notifications.map((notification, i) => {
                  return (
                    <>
                      <NotificationElement
                        key={notification.id}
                        id={notification.id}
                        user_id={notification.user_id}
                        title={notification.title}
                        description={notification.description}
                        resource_url={notification.resource_url}
                        read={notification.read}
                      />
                      {i + 1 < notifications.length && (
                        <Separator.Root className="bg-slate-200" />
                      )}
                    </>
                  );
                })
              ) : (
                <></>
              )}
              {!notifications.length && !isLoading && (
                <p className="m-5 text-sm text-gray-500">
                  No notifications yet
                </p>
              )}
              {isLoading && (
                <Loader className="rotating m-5 text-slate-400 size-8" />
              )}
            </div>
            <Popover.Arrow className="fill-white" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </>
  );
};

export { NotificationView };
