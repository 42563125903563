import { decrypt, getCookie } from "@/utils/auth";
import { User } from "@/utils/api";

export async function fetchUserData(): Promise<User | null> {
  const token = await getCookie("idToken");
  if (token) {
    const decoded = await decrypt(token);
    return {
      username: decoded["cognito:username"],
      name: decoded.name,
      email: decoded.email,
      role: decoded["custom:role"],
      county: decoded["custom:county"],
      department: decoded["custom:department"],
      designation: decoded["custom:designation"],
      timezone: JSON.parse(
        decoded["custom:timezone"] ? decoded["custom:timezone"] : "{}"
      ),
      profile_image: decoded["custom:profileImage"],
      chatModel: decoded["custom:chatModel"],
    };
  }
  return null;
}

export async function getInitials(username: string): Promise<string> {
  if (typeof username !== "string" || username.trim() === "") {
    return "";
  }

  const nameParts = username.trim().split(/\s+/);

  if (nameParts.length === 0) {
    return "";
  }

  if (nameParts.length === 1) {
    return nameParts[0]?.slice(0, 2).toUpperCase() ?? "";
  }

  const firstInitial = nameParts[0]?.[0] ?? "";
  const lastInitial = nameParts[nameParts.length - 1]?.[0] ?? "";

  return (firstInitial + lastInitial).toUpperCase();
}
