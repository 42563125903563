import { SendHorizonal } from "lucide-react";
import { ChangeEvent, useEffect, useRef, useState } from "react";

interface TextInputProps {
  text?:string;
  focus: boolean;
  password?: boolean;
  email?: boolean;
  onSubmit?: () => void;
  setText?: (value: string) => void;
  setFinal?: (value: string) => void;
}

const TextInput: React.FC<TextInputProps> = ({
  text,
  focus,
  password,
  email,
  setText,
  onSubmit,
  setFinal,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [currentValue, setCurrentValue] = useState<string>(text?text:"");
  const [emailError, setEmailError] = useState<string | null>(null);

  useEffect(() => {
    if (inputRef.current && focus) {
      inputRef.current.focus();
    }
  }, [focus]);

  const handleSetText = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (setText) {
      setText(value);
    } else {
      setCurrentValue(value);
    }
  };

  const handleOnSubmit = () => {
    if (email && emailError) {
      // Do not submit if there's an email error
        // Email validation regex pattern
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(currentValue)) {
          setEmailError("Invalid email address");
        } else {
          setEmailError(null);
        }
      return;
    }else{
        if (onSubmit) {
            onSubmit();
          } else if (setFinal) {
            setFinal(currentValue);
          }
          setCurrentValue("");
    }

  };

  return (
    <div
      style={{ position: "relative", display: "inline-block" }}
      className="w-full border-2 rounded-lg border-gray-300  mt-5 p-2 ">
      <input
        ref={inputRef}
        style={{
          marginLeft: 5,
          background: "white",
          outline: "none",
          padding: "8px 40px 8px 8px", // Padding right to leave space for the icon
          width: "100%", // Adjust width as necessary
           // Text color
        }}
        value={currentValue}
        type={password ? 'password':'text'}
        className="focus:outline-none text-gray-600"
        placeholder="Type..."
        onChange={handleSetText}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleOnSubmit();
          }
        }}
      />
      {email && emailError && (
        <div style={{ color: "red", marginTop: "4px" }}>{emailError}</div>
      )}
      <button
        style={{
          position: "absolute",
          top: "50%",
          right: 10,
          transform: "translateY(-50%)",
          border: "none",
          background: "none",
          cursor: "pointer",
        }}
        onClick={handleOnSubmit}
      >
        <SendHorizonal color="black" size={18} />
      </button>
    </div>
  );
};

export default TextInput;
