import React, { useState, useCallback, useEffect, useRef } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Badge } from "@/components/ui/badge";
import { toast } from "sonner";
import QuillEditor from "../markdown/QuillEditor";
import "react-quill/dist/quill.snow.css";
import {
  MPUDVersionFile,
  MPUDDocumentRulesAccept,
  MPUDDocumentRulesReject,
  MPUDDocumentRulesEdit,
  MPUDDocumentCreation,
} from "@/utils/api";
import {
  Check,
  X,
  Edit,
  AlertCircle,
  XCircle,
  Download,
  FileTextIcon,
  ListIcon,
  FilePlus2,
  FileText,
} from "lucide-react";
import { fetchUserData } from "@/utils/auth";
import { useSearchParams } from "react-router-dom";
import ImageModal from "./ImageModal";

type ParagraphStatus = "open" | "accepted" | "rejected";

interface Coordinates {
  Width: number;
  Height: number;
  Left: number;
  Top: number;
}

interface Condition {
  text: string;
  meta_data: {
    file_name: string;
    page_no: string;
    coordinates: Coordinates;
  };
}

interface Paragraph {
  id: string;
  title: string;
  conditions: Condition[];
  status: ParagraphStatus;
  last_updated: string;
}

const documentPreviewVariants = cva(
  "h-full flex flex-col shadow-[0_0.25rem_1.25rem_rgba(0,0,0,0.035)] focus-within:shadow-[0_0.25rem_1.25rem_rgba(0,0,0,0.075)] z-50 card-doc",
  {
    variants: {
      variant: {
        default: "",
        pdfViewer: "bg-white",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

interface DocumentPreviewProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof documentPreviewVariants> {
  initialDocument?: any;
  onClose?: () => void;
  fileId?: string;
  version_id?: string;
  chatId?: string;
  chatTitle?: string;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  initialDocument,
  onClose,
  variant,
  className,
  fileId,
  version_id,
  chatId,
  chatTitle,
  ...props
}) => {
  const [paragraphs, setParagraphs] = useState<Paragraph[]>(() => {
    if (variant === "pdfViewer" || !initialDocument) return [];
    return initialDocument.data;
  });
  const linkRefs = useRef<(HTMLUListElement | null)[]>([]);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editedText, setEditedText] = useState<string>("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [showPDF, setShowPDF] = useState(variant === "pdfViewer");
  const [countyName, setCountyName] = useState<string>("");
  const [showSourceModal, setShowSourceModal] = useState<number | null>(null);
  const [currentCondition, setCurrentCondition] = useState<Condition | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  useEffect(() => {
    const loadCounty = async () => {
      const userData = await fetchUserData();
      if (userData) {
        const countyWithState = userData.county;
        const countyName = countyWithState.split(",")[0] || "Pasco County";
        setCountyName(countyName);
      }
    };

    loadCounty();
  }, []);

  useEffect(() => {
    const handleClick = (e: Event) => {
      const target = e.target as HTMLElement;
      if (target.tagName.toLowerCase() === 'a') {
        e.preventDefault();
        const href = (target as HTMLAnchorElement).href;
        console.log('Link clicked:', href);

        const url = new URL(href);
        const fileName = url.searchParams.get('file_name');
        const pageNo = url.searchParams.get('page_no');

        if (fileName && pageNo) {
          setSearchParams({ file_name: fileName, page_no: pageNo });
        }
      }
    };

    linkRefs.current.forEach(ref => {
      if (ref) {
        ref.addEventListener('click', handleClick);
      }
    });

    return () => {
      linkRefs.current.forEach(ref => {
        if (ref) {
          ref.removeEventListener('click', handleClick);
        }
      });
    };
  }, [setSearchParams]);

  const handleFileClick = (condition: Condition) => {
    setCurrentCondition(condition);
    setIsImageModalOpen(true);
    setSearchParams({ 
      file_name: condition.meta_data.file_name, 
      page_no: condition.meta_data.page_no,
      left: condition.meta_data.coordinates.Left.toString(),
      top: condition.meta_data.coordinates.Top.toString(),
      width: condition.meta_data.coordinates.Width.toString(),
      height: condition.meta_data.coordinates.Height.toString()
    });
  };

  const handleCloseModal = () => {
    setIsImageModalOpen(false);
    setCurrentCondition(null);
    setSearchParams({ });
  };

  const handleStatusChange = async (index: number, status: ParagraphStatus) => {
    const paragraph = paragraphs[index];
    if (!paragraph) {
      console.error("Invalid paragraph index:", index);
      return;
    }

    const updatedParagraphs = paragraphs.map((p, i) =>
      i === index ? { ...p, status } : p
    );
    setParagraphs(updatedParagraphs);

    try {
      if (status === "accepted") {
        await handleAcceptedStatus(
          chatId || "",
          countyName,
          JSON.stringify([paragraph.id])
        );
      } else if (status === "rejected") {
        await handleRejectStatus(JSON.stringify([paragraph.id]), chatId || "");
      }
    } catch (error) {
      toast.error(`Error while updating status: ${error}`);
    }
  };

  const handleEdit = (index: number) => {
    const paragraph = paragraphs[index];
    if (paragraph) {
      setEditingIndex(index);
      setEditedText(JSON.stringify(paragraph.conditions, null, 2));
    }
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
    setEditedText("");
  };

  const handleSaveEdit = async () => {
    if (editingIndex !== null && chatId) {
      try {
        const editedConditions = JSON.parse(editedText);
        const updatedParagraphs = paragraphs.map((paragraph, i) =>
          i === editingIndex
            ? {
                ...paragraph,
                conditions: editedConditions,
                status: "accepted" as ParagraphStatus,
              }
            : paragraph
        );

        setParagraphs(updatedParagraphs);
        setEditingIndex(null);

        const editedParagraph = updatedParagraphs[editingIndex];
        if (editedParagraph) {
          await MPUDDocumentRulesEdit(chatId, JSON.stringify(editedConditions), editedParagraph.id);
        }
      } catch (error) {
        toast.error(`Failed to edit: ${error}`);
      }
    }
  };


  const handleGeneratePDF = useCallback(async () => {
    if (chatId) {
      setIsGenerating(true);
      try {
        const response = await MPUDDocumentCreation(
          countyName,
          chatTitle ?? "Untitled chat",
          chatId
        );
        if (!response.ok) {
          throw new Error("Failed to generate PDF");
        }

        const blob = await response.blob();
        setPdfBlob(blob);
        setShowPDF(true);
      } catch (error) {
        toast.error(`Error generating PDF: ${error}`);
      } finally {
        setIsGenerating(false);
      }
    }
  }, [chatId, countyName, chatTitle]);

  const handleDownload = useCallback(() => {
    if (pdfBlob) {
      const url = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "document.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  }, [pdfBlob]);

  const toggleView = () => {
    if (pdfBlob) {
      setShowPDF(!showPDF);
    }
  };

  const handleAcceptedStatus = async (
    chatId: string,
    countyName: string,
    rule_ids: string
  ) => {
    try {
      const acceptedResp = await MPUDDocumentRulesAccept(
        chatId,
        countyName,
        rule_ids
      );
      return acceptedResp;
    } catch (error) {
      toast.error(`Error while accepting rule: ${error}`);
      return error;
    }
  };

  const handleRejectStatus = async (ruleIds: string, chatId: string) => {
    try {
      const rejectResp = await MPUDDocumentRulesReject(ruleIds, chatId);
      return rejectResp;
    } catch (error) {
      toast.error(`Error while rejecting rule: ${error}`);
      return error;
    }
  };

  const handleShowSource = (index: number) => {
    setShowSourceModal(showSourceModal === index ? null : index);
  };

  const renderParagraph = (paragraph: Paragraph, index: number) => {
    let statusColor: string,
      statusIcon: JSX.Element,
      statusText: string,
      statusVariant: "default" | "secondary" | "destructive";

    switch (paragraph.status) {
      case "accepted":
        statusColor = "text-green-500";
        statusIcon = <Check className="w-4 h-4" />;
        statusVariant = "default";
        statusText = "Accepted";
        break;

      case "rejected":
        statusColor = "text-white";
        statusIcon = <X className="w-4 h-4" />;
        statusVariant = "destructive";
        statusText = "Rejected";
        break;

      default:
        statusColor = "text-zinc-700";
        statusIcon = <AlertCircle className="w-4 h-4" />;
        statusVariant = "secondary";
        statusText = "Pending Review";
    }

    return (
      <Card className="mb-4" key={paragraph.id}>
        <CardHeader>
          <CardTitle className="text-lg flex items-center">
            <Badge variant={statusVariant} className="mr-2">
              <span className={`mr-1 ${statusColor}`}>{statusIcon}</span>
              {statusText}
            </Badge>
            {paragraph.title}
          </CardTitle>
        </CardHeader>
        <CardContent>
          <ul ref={el => linkRefs.current[index] = el} className="mb-4 list-disc pl-5">
            {paragraph.conditions.map((condition, condIndex) => (
              <li key={condIndex} className="mb-2">
                {condition.text}
                <div className="mt-1">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => handleFileClick(condition)}
                  >
                    <FileText className="w-4 h-4 mr-1" /> View Source
                  </Button>
                </div>
              </li>
            ))}
          </ul>
          <div className="flex space-x-2 mt-4">
            <Button
              size="sm"
              variant={paragraph.status === "accepted" ? "default" : "outline"}
              onClick={() => handleStatusChange(index, "accepted")}
              disabled={paragraph.status === "accepted"}
            >
              <Check className="w-4 h-4 mr-1" /> Accept
            </Button>
            <Button
              size="sm"
              variant={
                paragraph.status === "rejected" ? "destructive" : "outline"
              }
              onClick={() => handleStatusChange(index, "rejected")}
              disabled={paragraph.status === "accepted"}
            >
              <X className="w-4 h-4 mr-1" /> Reject
            </Button>
            <Button size="sm" variant="outline" onClick={() => handleEdit(index)}>
              <Edit className="w-4 h-4 mr-1" /> Edit
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <Card
        className={documentPreviewVariants({ variant, className })}
        {...props}
      >
        <CardHeader className="flex flex-row items-center justify-between bg-zinc-700 text-white rounded-t-lg">
          <CardTitle>MPUD Document Preview</CardTitle>
          <div className="flex space-x-2">
            {variant === "default" && (
              <Button
                variant="ghost"
                size="icon"
                onClick={handleGeneratePDF}
                disabled={isGenerating || pdfBlob !== null}
                className="bg-zinc-700 text-white hover:bg-zinc-900 hover:text-white transition-colors"
              >
                <FilePlus2 className="h-6 w-6" />
              </Button>
            )}
            <Button
              variant="ghost"
              size="icon"
              onClick={handleDownload}
              disabled={!pdfBlob}
              className="bg-zinc-700 text-white hover:bg-zinc-900 hover:text-white transition-colors"
            >
              <Download className="h-6 w-6" />
            </Button>
            {variant === "default" && pdfBlob && (
              <Button
                variant="ghost"
                size="icon"
                onClick={toggleView}
                className="bg-zinc-700 text-white hover:bg-zinc-900 hover:text-white transition-colors"
              >
                {showPDF ? (
                  <ListIcon className="h-6 w-6" />
                ) : (
                  <FileTextIcon className="h-6 w-6" />
                )}
              </Button>
            )}
            {onClose && (
              <Button
              variant="ghost"
              size="icon"
              onClick={onClose}
              className="bg-zinc-700 text-white hover:bg-zinc-900 hover:text-white transition-colors"
            >
              <XCircle className="h-6 w-6" />
            </Button>
          )}
        </div>
      </CardHeader>
      <ScrollArea className="flex-grow pt-4">
        <CardContent>
          {(variant === "pdfViewer" || showPDF) && pdfBlob ? (
            <div className="flex-grow relative w-full h-[82vh]">
              <iframe
                src={URL.createObjectURL(pdfBlob)}
                className="absolute w-full h-full inset-0 border rounded"
                title="PDF Preview"
              />
            </div>
          ) : variant === "pdfViewer" ? (
            <div className="flex items-center justify-center h-[82vh]">
              <p>
                {isGenerating
                  ? "Generating PDF..."
                  : "No PDF available. Please wait while we generate it."}
              </p>
            </div>
          ) : !showPDF ? (
            paragraphs.map((paragraph, index) => (
              <div key={paragraph.id}>
                {editingIndex === index ? (
                  <Card className="mb-4">
                    <CardContent className="pt-6">
                      <QuillEditor
                        value={editedText}
                        onChange={(value: string) => setEditedText(value)}
                        className="w-full min-h-[100px] mb-2"
                      />
                      <div className="flex space-x-2">
                        <Button onClick={handleSaveEdit}>Save</Button>
                        <Button variant="outline" onClick={handleCancelEdit}>
                          Cancel
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                ) : (
                  renderParagraph(paragraph, index)
                )}
              </div>
            ))
          ) : null}
        </CardContent>
      </ScrollArea>
    </Card>
    <ImageModal
        isOpen={isImageModalOpen}
        onClose={handleCloseModal}
        condition={currentCondition}
      />
  </>
);
};

export default DocumentPreview;