import React, { createContext, useState, useEffect, useCallback } from "react";
import { getCookie, decrypt, setCookie, removeCookie } from "@/utils/auth";
import { refreshToken } from "@/utils/api";
import { toast } from "sonner";

interface AuthContextType {
  isAuthenticated: boolean;
  token: string | null;
  loading: boolean;
  refreshAuthToken: () => Promise<void>;
  login: (token: string) => Promise<void>;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

interface DecodedToken {
  exp: number;
  // Add other fields from your JWT as needed
}

const isTokenValid = async (token: string): Promise<boolean> => {
  try {
    const decodedToken = (await decrypt(token)) as DecodedToken;
    const currentTime = Date.now() / 1000;
    return decodedToken.exp > currentTime;
  } catch (error) {
    console.error("Error decoding token:", error);
    return false;
  }
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const refreshAuthToken = useCallback(async () => {
    try {
      const refreshTokenValue = await getCookie("refreshToken");
      if (refreshTokenValue) {
        await refreshToken();
        const newToken = await getCookie("idToken");
        if (newToken && (await isTokenValid(newToken))) {
          setToken(newToken);
          setIsAuthenticated(true);
        } else {
          throw new Error("Failed to refresh token");
        }
      } else {
        throw new Error("No refresh token available");
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      setToken(null);
      setIsAuthenticated(false);
    }
  }, []);

  const login = useCallback(async (newToken: string) => {
    if (await isTokenValid(newToken)) {
      setToken(newToken);
      setIsAuthenticated(true);
      await setCookie("idToken", newToken);
    } else {
      throw new Error("Invalid token");
    }
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setIsAuthenticated(false);
    removeCookie("idToken");
    removeCookie("refreshToken");
  }, []);

  const checkAuthStatus = useCallback(async () => {
    setLoading(true);
    try {
      const idToken = await getCookie("idToken");
      if (idToken) {
        if (await isTokenValid(idToken)) {
          if (idToken !== token) {
            setToken(idToken);
            setIsAuthenticated(true);
          }
        } else {
          toast.loading("Refreshing token...", { id: "refreshingToken" });
          await refreshAuthToken();
          toast.success("Token refreshed", { id: "refreshingToken" });
        }
      } else {
        setToken(null);
        setIsAuthenticated(false);
      }
      if (!idToken) {
        // If idToken is not present, try to refresh using the refresh token
        const refreshTokenValue = await getCookie("refreshToken");
        if (refreshTokenValue) {
          toast.loading("Refreshing token...", { id: "refreshingToken" });
          await refreshAuthToken();
          toast.success("Token refreshed", { id: "refreshingToken" });
        }
      }
    } catch (error) {
      console.error("Error checking auth status:", error);
      setToken(null);
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  }, [token, refreshAuthToken]);

  useEffect(() => {
    checkAuthStatus();

    const intervalId = setInterval(checkAuthStatus, 5000); // Check every 5 seconds

    return () => clearInterval(intervalId);
  }, [checkAuthStatus]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        token,
        loading,
        refreshAuthToken,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
