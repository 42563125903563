import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Loader2 } from "lucide-react";
import { getHeaders, BACKEND_BASE_URL } from '@/utils/api';  // Ensure this path is correct

interface Coordinates {
  Width: number;
  Height: number;
  Left: number;
  Top: number;
}

interface Condition {
  text: string;
  meta_data: {
    file_name: string;
    page_no: string;
    coordinates: Coordinates;
  };
}

interface ImageModalProps {
  isOpen: boolean;
  onClose: () => void;
  condition: Condition | null;
}

async function getMPUDPreview(condition: Condition): Promise<Blob> {
  const url = `${BACKEND_BASE_URL}/source/mpud-preview/`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: await getHeaders(),
      body: JSON.stringify({
        file_name: condition.meta_data.file_name,
        page_no: parseInt(condition.meta_data.page_no),
        coordinates: condition.meta_data.coordinates
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.blob();
  } catch (error) {
    console.error('Error getting MPUD preview:', error);
    throw error;
  }
}

const ImageModal: React.FC<ImageModalProps> = ({
  isOpen,
  onClose,
  condition,
}) => {
  const [imageData, setImageData] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchImage = async () => {
      if (isOpen && condition) {
        setIsLoading(true);
        try {
          const blob = await getMPUDPreview(condition);
          const reader = new FileReader();
          reader.onloadend = () => {
            setImageData(reader.result as string);
            setIsLoading(false);
          };
          reader.readAsDataURL(blob);
        } catch (error) {
          console.error("Error fetching image:", error);
          setIsLoading(false);
        }
      } else {
        setImageData(null);
        setIsLoading(true);
      }
    };

    fetchImage();
  }, [isOpen, condition]);

  if (!condition) return null;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="fixed flex items-center justify-center">
        <div className="bg-white rounded-lg relative overflow-auto min-h-[70vh] max-h-[90vh] max-w-[90vw]">
          <DialogHeader className="flex flex-row items-center justify-between">
            <DialogTitle>Source Document</DialogTitle>
          </DialogHeader>
          <div className="mt-4 ">
            {isLoading ? (
              <div className="absolute inset-0 flex items-center justify-center">
                <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
              </div>
            ) : imageData ? (
              <div className="relative w-full">
                <img
                  src={imageData}
                  alt={`Page ${condition.meta_data.page_no} from ${condition.meta_data.file_name}`}
                  className="w-full rounded-md "
                />
                <div
                  className="absolute bg-yellow-300 bg-opacity-40"
                  style={{
                    left: `${(condition.meta_data.coordinates.Left - 0.005) * 100}%`,
                    top: `${(condition.meta_data.coordinates.Top - 0.005) * 100}%`,
                    width: `${(condition.meta_data.coordinates.Width + 0.01) * 100}%`,
                    height: `${(condition.meta_data.coordinates.Height + 0.01) * 100}%`,
                  }}
                ></div>
              </div>
            ) : (
              <div className="absolute inset-0 flex items-center justify-center text-gray-500">
                Failed to load image
              </div>
            )}
          </div>
          <p className='mt-5 text-xs'><strong>Source name :</strong>{condition.meta_data.file_name}</p>
          <p className='mt-5 text-xs'><strong>Page no :</strong>{condition.meta_data.page_no}</p>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ImageModal;