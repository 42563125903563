import React, { useState } from "react";
import Sidebar from "@/components/sidebar/Sidebar";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { NotificationView } from "@/components/ui/notification";
import { toast, Toaster } from "sonner";

function ChatLayout() {
  const [activeChatTitle, setActiveChatTitle] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const handelNewChat = () => {
    if (location.pathname !== "/chat") {
      navigate("/chat");
    }
  };

  const handelMPUDVersionSelect = (version_id: string, fileId: string) => {
    navigate(`/MPUDPreview/${fileId}`, {
      state: {
        fileId: fileId,
        version_id: version_id,
      },
      replace: true, // This ensures that the state is updated even when navigating to the same route
    });
  };

  return (
    <>
      <div className="flex h-screen">
        <Sidebar
          recentChats={[]}
          onNewChat={handelNewChat}
          onChatSelect={function (chatId: string, chatTitle: string): void {
            navigate(`/chat/${chatId}`, { state: { chatTitle: chatTitle } });
          }}
          sendSelectedChatTitle={(title: string) => {
            setActiveChatTitle(title);
          }}
          onMPUDVersionSelect={handelMPUDVersionSelect}
        />

        <main className="min-h-full w-full min-w-0 flex-1 flex justify-center items-center">
          <Outlet
            context={{ area: "main", activeChatTitle: activeChatTitle }}
          />
        </main>

        <NotificationView
          onMarkedAsRead={() =>
            toast.success("All notifications marked as read")
          }
        />
      </div>
      <Toaster position="top-right" richColors />
    </>
  );
}

export default ChatLayout;
