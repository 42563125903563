import TextInput from "@/components/ui/TextInput";
import TypingEffect from "@/components/ui/TypingEffect";
import { useEffect, useRef, useState } from "react";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import BotMessageSquare from "@/components/ui/BotMessageSquare";
import { confirmSignUp, resendConfirmationCode, signUpUser } from "@/utils/api";
import { toast } from "sonner";
import { useLocation, useNavigate } from "react-router-dom";
import { RotateCw } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "../ui/skeleton";

interface OTPInputprops {
  value: string;
  setValue: (value: string) => void;
}

const OTPInput: React.FC<OTPInputprops> = ({ value, setValue }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="space-y-2">
      <InputOTP
        className="border-gray-600"
        maxLength={6}
        value={value}
        onChange={(value: string) => setValue(value)}
        ref={inputRef}
      >
        <InputOTPGroup className="border-gray-300 border-2 rounded-lg">
          <InputOTPSlot index={0} />
          <InputOTPSlot index={1} />
          <InputOTPSlot index={2} />
          <InputOTPSlot index={3} />
          <InputOTPSlot index={4} />
          <InputOTPSlot index={5} />
        </InputOTPGroup>
      </InputOTP>
    </div>
  );
};

interface County {
  name: string;
  id: string;
}

interface CountyDropdownProps {
  value: string;
  onChange: (value: string) => void;
}

const CountyDropdown: React.FC<CountyDropdownProps> = ({ value, onChange }) => {
  const [counties, setCounties] = useState<County[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCounties = async () => {
      try {
        const response = await fetch(
          "https://api.census.gov/data/2022/acs/acs5/subject?get=NAME&for=county:*&in=state:12"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch counties");
        }
        const data: any[][] = await response.json();
        // The first item is the header, so we slice it off
        const countyList: County[] = data
          .slice(1)
          .map((item) => ({
            name: item[0],
            id: `${item[2]},${item[1]}`, // state code + county code
          }))
          .filter(
            (item): item is County =>
              typeof item.name === "string" &&
              item.name.trim() !== "" &&
              typeof item.id === "string" &&
              item.id.trim() !== ""
          );
        setCounties(countyList);
        setLoading(false);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
        setLoading(false);
      }
    };

    fetchCounties();
  }, []);

  if (loading) {
    return (
      <div className="pt-4 space-y-2">
        <Skeleton className="h-10 w-full" />
        <Skeleton className="h-4 w-3/4" />
        <Skeleton className="h-4 w-1/2" />
      </div>
    );
  }

  if (error)
    return <p className="text-red-500">Error loading counties: {error}</p>;

  return (
    <div className="pt-4">
      <Select value={value} onValueChange={onChange}>
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Select your county" />
        </SelectTrigger>
        <SelectContent className="max-h-[300px] overflow-y-auto">
          {counties.map((county) => (
            <SelectItem key={county.id} value={county.name}>
              {county.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

const ChatSignup: React.FC = () => {
  const [showInput, setShowInput] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [county, setCounty] = useState<string>("");
  const [emailInput, setEmailInput] = useState<string>("");
  const [passwordInput, setPasswordInput] = useState<string>("");
  const [verification, setVerification] = useState<string>("");
  const [currentField, setCurrentField] = useState<string>("Name");
  const [completedFields, setCompletedFields] = useState<Set<string>>(
    new Set()
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fields = [
    "Name",
    "County",
    "Email",
    "Password",
    "Confirm Password",
    "Verify",
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (queryParams.has("verify")) {
      const email = queryParams.get("verify");
      setEmailInput(email ? email : "");
      setCurrentField("Verify");
    } else {
      window.history.pushState({}, "", `/signup`);
    }
  }, []);

  const resetSignupState = () => {
    setName("");
    setCounty("");
    setEmailInput("");
    setPasswordInput("");
    setVerification("");
    setCurrentField("Name");
    setShowInput(false);
    setIsLoading(false);
  };

  const signingUp = async () => {
    setIsLoading(true);
    try {
      await signUpUser(emailInput, passwordInput, name, county);
      setCurrentField("Verify");
      toast.success(
        "Signup successful! Please check your email for the verification code."
      );
    } catch (error: any) {
      if (error.message.includes("400")) {
        toast.error("Username (email) already exists");
      } else {
        toast.error("Signup failed. Please check your details.");
      }
      resetSignupState();
    } finally {
      setIsLoading(false);
    }
  };

  const confirming = async (vcode: string) => {
    setIsLoading(true);
    try {
      await confirmSignUp(emailInput, vcode);
      toast.success("Your account has been verified successfully!");
      window.location.href = "/login"; // TODO: use navigate method
    } catch (error) {
      toast.error("Invalid verification code!");
      setVerification("");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (verification.length === 6) {
      confirming(verification);
    }
  }, [verification]);

  const handleFieldCompletion = (text: string) => {
    switch (currentField) {
      case "Name":
        setName(text);
        setCompletedFields((prev) => new Set(prev).add("Name"));
        setCurrentField("County");
        break;
      case "County":
        setCounty(text);
        setCompletedFields((prev) => new Set(prev).add("County"));
        setCurrentField("Email");
        break;
      case "Email":
        setEmailInput(text);
        setCompletedFields((prev) => new Set(prev).add("Email"));
        setCurrentField("Password");
        break;
      case "Password":
        setPasswordInput(text);
        setCompletedFields((prev) => new Set(prev).add("Password"));
        setCurrentField("Confirm Password");
        break;
      case "Confirm Password":
        if (text === passwordInput) {
          setCompletedFields((prev) => new Set(prev).add("Confirm Password"));
          signingUp();
        } else {
          toast.error("Passwords do not match. Please try again.");
          setCurrentField("Password");
          setPasswordInput("");
          setCompletedFields((prev) => {
            const newSet = new Set(prev);
            newSet.delete("Password");
            newSet.delete("Confirm Password");
            return newSet;
          });
        }
    }
    setShowInput(false);
  };
  const renderCurrentField = () => {
    if (isLoading) {
      return (
        <div role="status">
          <svg
            aria-hidden="true"
            className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-200 fill-gray-600 ml-28 mt-5"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
      );
    }

    let message = "";
    let isPassword = false;

    switch (currentField) {
      case "Name":
        message = "Hello there, May I know your name Please?";
        break;
      case "County":
        message = `Nice to meet you ${name}! What county are you from?`;
        break;
      case "Email":
        message = `Thanks! Now, your email please?`;
        break;
      case "Password":
        message = `Please enter your password`;
        isPassword = true;
        break;
      case "Confirm Password":
        message = `Please confirm your password`;
        isPassword = true;
        break;
      case "Verify":
        window.history.pushState({}, "", `/signup?verify=${emailInput}`);
        return (
          <>
            <div className="flex text-xl items-center flex-wrap">
              <BotMessageSquare /> &nbsp;&nbsp;
              <TypingEffect
                text={`Hey ${name}, Please enter the OTP sent to ${emailInput}`}
                onComplete={() => setShowInput(true)}
              />
            </div>
            <div
              className="text-sm flex justify-start text-gray-500 mt-5 hover:underline cursor-pointer"
              onClick={async () => {
                try {
                  await resendConfirmationCode(emailInput);
                  toast.success(
                    `Verification code sent to mail ${emailInput} successfully.`
                  );
                } catch (e) {
                  toast.error("Sorry! Unable to send verification code");
                }
              }}
            >
              <RotateCw width={18} height={18} /> &nbsp;Resend
            </div>
            <div className="mt-5">
              <OTPInput value={verification} setValue={setVerification} />
            </div>
          </>
        );
    }

    return (
      <>
        <div className="flex gap-2 text-xl items-center">
          <BotMessageSquare /> 
          <TypingEffect text={message} onComplete={() => setShowInput(true)} />
        </div>
        {showInput &&
          (currentField === "County" ? (
            <CountyDropdown
              value={county}
              onChange={(value) => {
                setCounty(value);
                handleFieldCompletion(value);
              }}
            />
          ) : (
            <TextInput
              focus={true}
              password={isPassword}
              setFinal={handleFieldCompletion}
            />
          ))}
      </>
    );
  };

  const canNavigateToField = (field: string): boolean => {
    const fieldOrder = [
      "Name",
      "County",
      "Email",
      "Password",
      "Confirm Password",
      "Verify",
    ];
    const currentIndex = fieldOrder.indexOf(currentField);
    const targetIndex = fieldOrder.indexOf(field);
    return targetIndex <= currentIndex || completedFields.has(field);
  };

  return (
    <div className="block mx-2">
      <div className="flex justify-start my-8">
        <sub className="leading-relaxed">
          {fields.map((field: string, index: number) => (
            <span
              key={index}
              className={`text-gray-500 ${canNavigateToField(field) ? "cursor-pointer" : "cursor-not-allowed opacity-50"}`}
              onClick={() => {
                if (field !== "Verify" && canNavigateToField(field)) {
                  setCurrentField(field);
                }
              }}
            >
              {currentField === field ? (
                <span className="text-gray-600 underline"> {field} </span>
              ) : (
                <span className="hover:underline"> {field} </span>
              )}
              {index !== fields.length - 1 && <>•</>}
            </span>
          ))}
        </sub>
      </div>

      {renderCurrentField()}
    </div>
  );
};

export default ChatSignup;
