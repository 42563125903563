import React, { useState, useRef, useEffect, useCallback } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Edit, Save, Check, ChevronsUpDown } from "lucide-react";
import { toast } from "sonner";
import { fetchUserData } from "@/utils/user";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import {
  getUserProfileImage,
  refreshToken,
  updateUserData,
  updateUserProfileImage,
} from "@/utils/api";
import { getCookie } from "@/utils/auth";

type Timezone = {
  value: string;
  label: string;
  area: string;
  gmtOffset: string;
};

type GroupedTimezones = {
  [key: string]: Timezone[];
};

const PersonalSettingsForm: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [county, setCounty] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [timezone, setTimezone] = useState({ display: "", gmt: "" });
  const [chatModel, setChatModel] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [profileImage, setProfileImage] = useState<string | undefined>(
    undefined
  );
  const [isImageLoading, setIsImageLoading] = useState(true);
  const imageLoadedRef = useRef(false);
  const profileImageUrlRef = useRef<string | null>(null);
  const [groupedTimezones, setGroupedTimezones] = useState<GroupedTimezones>(
    {}
  );
  const [open, setOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const getInitials = (name: string): string => {
    if (typeof name !== "string" || name.trim() === "") {
      return "";
    }

    const nameParts = name.trim().split(/\s+/);

    if (nameParts.length === 0) {
      return "";
    }

    if (nameParts.length === 1) {
      return nameParts[0]?.slice(0, 2).toUpperCase() ?? "";
    }

    const firstInitial = nameParts[0]?.[0] ?? "";
    const lastInitial = nameParts[nameParts.length - 1]?.[0] ?? "";

    return (firstInitial + lastInitial).toUpperCase();
  };

  const loadUserData = useCallback(async () => {
    setIsLoading(true);
    try {
      const userData = await fetchUserData();
      if (userData) {
        setName(userData.name || "");
        setEmail(userData.email || "");
        setCounty(userData.county || "");
        setDesignation(userData.designation || "");
        setDepartment(userData.department || "");
        setTimezone(userData.timezone || { display: "", gmt: "" });
        setChatModel(userData.chatModel || "");

        if (userData.profile_image) {
          // Only load the profile image if it hasn't been loaded before
          if (!imageLoadedRef.current) {
            loadProfileImage(userData.username);
          }
        } else {
          setProfileImage(undefined);
          setIsImageLoading(false);
          imageLoadedRef.current = true;
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to load user data. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  const loadProfileImage = async (username: string) => {
    if (imageLoadedRef.current) return;

    setIsImageLoading(true);
    try {
      const profileImage = await getUserProfileImage(username, 128);
      const imageUrl = URL.createObjectURL(profileImage);
      setProfileImage(imageUrl);
      imageLoadedRef.current = true;
    } catch (error) {
      console.error("Error loading profile image:", error);
      setProfileImage("/path/to/default/image.png"); // Set a default image
    } finally {
      setIsImageLoading(false);
    }
  };

  // Effect for managing profile image URL
  useEffect(() => {
    const currentUrl = profileImageUrlRef.current;
    return () => {
      // Cleanup function to revoke object URL
      if (currentUrl && currentUrl.startsWith("blob:")) {
        URL.revokeObjectURL(currentUrl);
      }
    };
  }, []);

  const fetchTimezones = async () => {
    try {
      const response = await fetch("https://worldtimeapi.org/api/timezone");
      const data = await response.json();
      const formattedTimezones: Timezone[] = data.map((tz: string) => {
        let area, label, gmtOffset;

        if (tz.includes("/")) {
          [area, ...label] = tz.split("/");
          label = label.join(" / ").replace(/_/g, " ");
        } else {
          area = "Other";
          label = tz;
        }

        // Remove "Etc/" prefix and handle special cases
        if (area === "Etc") {
          area = "GMT/UTC";
          label = label.replace("GMT", "GMT ");
          gmtOffset = label.replace("GMT ", "");
        } else {
          // For non-Etc timezones, we need to calculate the GMT offset
          const date = new Date().toLocaleString("en-US", { timeZone: tz });
          const utcDate = new Date().toLocaleString("en-US", {
            timeZone: "UTC",
          });
          const diffInMinutes =
            (new Date(date).getTime() - new Date(utcDate).getTime()) / 60000;
          const hours = Math.floor(Math.abs(diffInMinutes) / 60);
          const minutes = Math.abs(diffInMinutes) % 60;
          gmtOffset = `GMT${diffInMinutes >= 0 ? "+" : "-"}${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
        }

        return {
          value: tz,
          label: label || "",
          area: area || "Other",
          gmtOffset: gmtOffset || "",
        };
      });

      const grouped = formattedTimezones.reduce(
        (acc: GroupedTimezones, tz: Timezone) => {
          if (!acc[tz.area]?.length) {
            acc[tz.area] = [];
          }
          acc[tz.area]?.push(tz); // Add the optional chaining operator here
          return acc;
        },
        {}
      );

      setGroupedTimezones(grouped);
    } catch (error) {
      console.error("Error fetching timezones:", error);
      toast.error("Failed to load timezones. Please try again later.");
    }
  };

  useEffect(() => {
    loadUserData();
    fetchTimezones();
  }, [loadUserData]);

  const handleSubmit = async () => {
    // Handle form submission logic here
    setIsEditing(false);
    const toastId = toast.loading("Saving changes...");
    const username = await getCookie("userId");
    if (!username) {
      toast.error("Failed to save changes. Please try again later.", {
        id: toastId,
      });
      return;
    }
    try {
      await updateUserData(username, {
        name: name,
        designation: designation,
        department: department,
        timezone: timezone.display ? timezone : { display: "", gmt: "" },
        chatModel: chatModel,
      });
      if (fileInputRef.current?.files?.[0]) {
        await updateUserProfileImage(
          username,
          fileInputRef.current?.files?.[0]
        );
      }
      await refreshToken();
      loadUserData();
      toast.success("Changes saved successfully!", { id: toastId });
    } catch (error) {
      toast.error("Failed to save changes. Please try again later.", {
        id: toastId,
      });
    } finally {
      loadUserData();
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    loadUserData(); // Reset to original data
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.onload = () => {
          setProfileImage(reader.result as string);
        };
        img.onerror = () => {
          toast.error(
            "Cannot load image, please try again with a different image."
          );
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        };
        img.src = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAvatarClick = () => {
    if (isEditing && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const SkeletonField = () => (
    <div className="space-y-2">
      <Skeleton className="h-4 w-[100px]" />
      <Skeleton className="h-10 w-full" />
    </div>
  );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="flex justify-between items-center mb-6">
            <Skeleton className="h-8 w-[200px]" />
            <Skeleton className="h-10 w-[100px]" />
          </div>
          <div className="space-y-6">
            <div className="flex items-center space-x-6">
              <Skeleton className="h-24 w-24 rounded-full" />
              <div className="flex-grow grid grid-cols-2 gap-4">
                <SkeletonField />
                <SkeletonField />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4">
              <SkeletonField />
              <SkeletonField />
              <SkeletonField />
            </div>
            <SkeletonField />
            <SkeletonField />
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent className="pt-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Personal Settings</h2>
          {isEditing ? (
            <div className="space-x-2">
              <Button onClick={handleCancel} variant="outline">
                Cancel
              </Button>
              <Button onClick={handleSubmit}>
                <Save className="w-4 h-4 mr-2" />
                Save Changes
              </Button>
            </div>
          ) : (
            <Button onClick={() => setIsEditing(true)}>
              <Edit className="w-4 h-4 mr-2" />
              Edit
            </Button>
          )}
        </div>
        <div className="space-y-6">
          <div className="flex items-center space-x-6">
            <div className="flex-shrink-0">
              <Avatar
                className={`w-24 h-24 ${isEditing ? "cursor-pointer" : ""}`}
                onClick={handleAvatarClick}
              >
                {isImageLoading ? (
                  <Skeleton className="w-24 h-24" />
                ) : (
                  <>
                    <AvatarImage
                      src={profileImage}
                      alt="Profile"
                      onLoad={() => setIsImageLoading(false)}
                    />
                    <AvatarFallback className="text-xl font-semibold">
                      {getInitials(name)}
                    </AvatarFallback>
                  </>
                )}
              </Avatar>
              <Input
                ref={fileInputRef}
                type="file"
                accept="image/png, image/jpeg, image/gif"
                className="hidden"
                onChange={handleImageUpload}
                disabled={!isEditing}
              />
            </div>
            <div className="flex-grow grid grid-cols-2 gap-4">
              <div className="space-y-1">
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your name"
                  disabled={!isEditing}
                />
              </div>
              <div className="space-y-1">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  readOnly
                  className="bg-gray-100"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4">
            <div className="space-y-1">
              <Label htmlFor="designation">Designation</Label>
              <Input
                id="designation"
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
                placeholder="Enter your designation"
                disabled={!isEditing}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="department">Department</Label>
              <Input
                id="department"
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
                placeholder="Enter your department"
                disabled={!isEditing}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="county">County</Label>
              <Input
                id="county"
                value={county}
                readOnly
                className="bg-zinc-100"
              />
            </div>
          </div>

          <div className="space-y-2">
            <Label htmlFor="timezone">Timezone</Label>
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <Button
                  id="timezone"
                  variant="outline"
                  role="combobox"
                  aria-expanded={open}
                  className="w-full justify-between"
                  disabled={!isEditing}
                >
                  {timezone.display || "Select timezone..."}
                  <ChevronsUpDown className="w-4 h-4 ml-2" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[400px] p-0">
                <Command>
                  <CommandInput placeholder="Search timezone..." />
                  <CommandEmpty>No timezone found.</CommandEmpty>
                  <CommandList className="max-h-[300px] overflow-y-auto">
                    {Object.entries(groupedTimezones).map(
                      ([area, timezones]) => (
                        <CommandGroup key={area} heading={area}>
                          {timezones.map((tz) => (
                            <CommandItem
                              key={tz.value}
                              value={tz.label}
                              onSelect={() => {
                                setTimezone({
                                  display: tz.label,
                                  gmt: tz.gmtOffset,
                                });
                                setOpen(false);
                              }}
                            >
                              <Check
                                className={cn(
                                  "mr-2 h-4 w-4",
                                  timezone.display === tz.label
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                              <span className="flex-grow">{tz.label}</span>
                              <span className="text-sm text-muted-foreground">
                                ({tz.gmtOffset})
                              </span>
                            </CommandItem>
                          ))}
                          <CommandSeparator />
                        </CommandGroup>
                      )
                    )}
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </div>

          <div className="space-y-2">
            <Label htmlFor="chat-model">Preferred Chat Model</Label>
            <Select
              value={chatModel}
              onValueChange={setChatModel}
              disabled={!isEditing}
            >
              <SelectTrigger id="chat-model">
                <SelectValue placeholder="Select a chat model" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="gpt-35-turbo">GPT-3.5 Turbo</SelectItem>
                <SelectItem value="gpt-4">GPT-4</SelectItem>
                <SelectItem value="claude-2">Claude 2</SelectItem>
                <SelectItem value="claude-3">Claude 3</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default PersonalSettingsForm;
