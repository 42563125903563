import React from "react";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { ChatMessage } from "@/utils/api";

interface ChatMessagesProps {
  messages: ChatMessage[];
  username: string;
}

interface Message {
  sender: "ai" | "human";
  content: string;
  timestamp: string;
  username: string;
}

const Message: React.FC<Message> = ({
  sender,
  content,
  timestamp,
  username,
}) => {
  const parseTimestamp = (stamp: string) => {
    // The time returned from backend is in UTC
    let dateTime = new Date(`${stamp}Z`);
    if (isNaN(dateTime.getTime())) dateTime = new Date(stamp);

    const localDate = dateTime.toLocaleString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return localDate;
  };

  const extractAnswer = (chunk: string) => {
    const isDocstring = /^(```)/.test(chunk);
    if (!isDocstring) return chunk;

    let cleanedResponse = chunk.replace(/```json\n|```/g, "");
    cleanedResponse = cleanedResponse.replace(/"""/g, '"');
    cleanedResponse = cleanedResponse.replace(/\n\s+/g, " ");
    cleanedResponse = cleanedResponse.replace(/\\n/g, " ");

    const answerMatch = cleanedResponse.match(/"answer"\s*:\s*"([^"]*)/);
    if (answerMatch && answerMatch[1]) {
      return answerMatch[1];
    }

    return "";
  };


  return (
    <div
      className={`flex-col mb-4 space-x-2 p-2 pr-4 rounded-lg overflow-wrap ${sender === "human" ? "bg-zinc-100" : "bg-zinc-200"}`}
    >
      <div className="flex items-start">
        {sender === "human" && (
          <Avatar className="w-8 h-8 flex-none">
            <AvatarFallback>{username}</AvatarFallback>
          </Avatar>
        )}
        <div className="flex flex-grow ml-2 items-center h-full">
          <span>{sender === "ai" ? extractAnswer(content) : content}</span>
        </div>
      </div>
      <br />
      <span className="text-xs font-thin text-slate-500">
        {parseTimestamp(timestamp)}
      </span>
    </div>
  );
};

const ChatMessages: React.FC<ChatMessagesProps> = ({ messages, username }) => {
  return (
    <>
      {messages.map((message, index) => (
        <React.Fragment key={`message-${index}`}>
          <Message
            key={`human-${index}`}
            content={message.content.human.message}
            sender="human"
            timestamp={message.timestamp}
            username={username}
          />
          <Message
            key={`ai-${index}`}
            content={message.content.ai.message}
            sender="ai"
            timestamp={message.timestamp}
            username={username}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default ChatMessages;
