// Importing necessary libraries
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { User } from "@/utils/api";

// Function to set a cookie
export async function setCookie(
  name: string,
  value: string,
  options?: Cookies.CookieAttributes
): Promise<void> {
  try {
    Cookies.set(name, value, options);
  } catch (error) {
    console.error(`Error setting cookie ${name}:`, error);
    throw error;
  }
}

// Function to get a cookie
export async function getCookie(name: string): Promise<string | undefined> {
  try {
    const cookie = Cookies.get(name);
    return cookie;
  } catch (error) {
    console.error(`Error getting cookie ${name}:`, error);
    throw error;
  }
}

// Function to remove a cookie
export async function removeCookie(name: string): Promise<void> {
  try {
    Cookies.remove(name);
  } catch (error) {
    console.error(`Error removing cookie ${name}:`, error);
    throw error;
  }
}

// Define the type for the decoded JWT
interface DecodedJWT {
  // Define properties here based on what you expect in your JWT
  exp?: number;
  iat?: number;
  [key: string]: any;
}

// Function to decrypt and decode a JWT
export async function decrypt(input: string): Promise<DecodedJWT> {
  try {
    const decoded: DecodedJWT = jwtDecode(input);
    console.log("Decoded JWT:", decoded);
    return decoded;
  } catch (error) {
    console.error("Error decoding JWT:", error);
    throw error;
  }
}

export async function fetchUserData(): Promise<User | null> {
  const token = await getCookie("idToken");
  if (token) {
    const decoded = await decrypt(token);
    return {
      username: decoded["cognito:username"],
      name: decoded.name,
      email: decoded.email,
      role: decoded["custom:role"],
      county: decoded["custom:county"],
    };
  }
  return null;
}
