import React, { useState, useRef, useEffect } from "react";
import { ArrowUp, Paperclip, Loader, X } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import { toast } from "sonner";

const chatInputVariants = cva(
  "p-4 bg-white shadow-zinc-300/50 shadow-[0_0.25rem_1.25rem_rgba(0,0,0,0.035)] focus-within:shadow-[0_0.25rem_1.25rem_rgba(0,0,0,0.075)] z-50 relative",
  {
    variants: {
      variant: {
        default: "rounded-t-xl border-t border-y",
        rounded: "rounded-xl border",
        withAttachment: "rounded-t-xl border-t border-y",
      },
      size: {
        default: "",
        tall: "py-6",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

interface ChatInputProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof chatInputVariants> {
  onSend: (content: string, files?: File[]) => void;
  onAttach?: () => void;
  disabled?: boolean;
}

const ChatInput = React.forwardRef<HTMLDivElement, ChatInputProps>(
  ({ onSend, onAttach, variant, size, className, disabled, ...props }, ref) => {
    const [input, setInput] = useState("");
    const [files, setFiles] = useState<File[]>([]);
    const [isDragging, setIsDragging] = useState(false);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const dragCounterRef = useRef(0);

    const handleSend = () => {
      if ((input.trim() || files.length > 0) && !disabled) {
        onSend(input, files);
        setInput("");
        setFiles([]);
      }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setInput(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSend();
      }
    };

    const isPDF = (file: File) => {
      return file.type === "application/pdf";
    };

    const handleFileValidation = (newFiles: File[]) => {
      const pdfs = newFiles.filter(isPDF);
      const nonPdfs = newFiles.filter((file) => !isPDF(file));

      if (nonPdfs.length > 0) {
        toast.error("Only PDF files are allowed.");
        return;
      }

      if (pdfs.length > 0) {
        setFiles((prevFiles) => [...prevFiles, ...pdfs]);
      }
    };

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounterRef.current++;
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setIsDragging(true);
      }
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounterRef.current--;
      if (dragCounterRef.current === 0) {
        setIsDragging(false);
      }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      dragCounterRef.current = 0;
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const droppedFiles = Array.from(e.dataTransfer.files);
        handleFileValidation(droppedFiles);
        e.dataTransfer.clearData();
      }
    };

    const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const selectedFiles = Array.from(e.target.files);
        handleFileValidation(selectedFiles);
      }
    };

    const removeFile = (index: number) => {
      setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height = `${Math.min(
          textareaRef.current.scrollHeight,
          160
        )}px`;
      }
    }, [input]);

    useEffect(() => {
      if (!disabled) textareaRef.current?.focus();
    }, [disabled]);

    return (
      <div
        className={cn(chatInputVariants({ variant, size, className }))}
        ref={ref}
        {...props}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {isDragging && (
          <div className="absolute inset-0 bg-blue-50 border-2 border-dashed border-blue-300 rounded-lg flex items-center justify-center z-10 pointer-events-none">
            <p className="text-blue-500 font-medium">
              Drop PDF files here to upload
            </p>
          </div>
        )}
        <div className="flex flex-col space-y-2">
          {files.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center bg-gray-100 rounded-full px-3 py-1 text-sm"
                >
                  <span className="truncate max-w-[150px]">{file.name}</span>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="ml-2 p-0"
                    onClick={() => removeFile(index)}
                  >
                    <X className="w-4 h-4" />
                  </Button>
                </div>
              ))}
            </div>
          )}
          <div className="flex items-start space-x-2">
            <Textarea
              ref={textareaRef}
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Type your message..."
              className="flex-grow min-h-[40px] max-h-[160px] overflow-y-auto border-none focus:border-none active:border-none"
              style={{
                resize: "none",
              }}
              disabled={disabled}
            />

            <Button
              onClick={handleSend}
              className="bg-zinc-700 text-white hover:bg-zinc-900 hover:text-white transition-colors"
              variant="outline"
              size="icon"
              disabled={disabled || (!input.trim() && files.length === 0)}
            >
              {disabled ? (
                <Loader className="rotating" />
              ) : (
                <ArrowUp className="w-4 h-4" />
              )}
            </Button>

            {variant === "withAttachment" && onAttach && (
              <Button onClick={onAttach} variant="outline" size="icon">
                <Paperclip className="w-4 h-4" />
              </Button>
            )}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileInput}
              className="hidden"
              multiple
              accept=".pdf"
            />
          </div>
        </div>
        <div className="mt-2 text-sm text-zinc-500">
          Hint: Press Enter to send, Shift + Enter for new line. Drag and drop
          PDF files to attach.
        </div>
      </div>
    );
  }
);

export default ChatInput;
