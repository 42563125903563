import React from "react";
import Sidebar from "@/components/sidebar/Sidebar";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

function SettingsLayout() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNewChat = () => {
    if (location.pathname !== "/chat") {
      navigate("/chat");
    }
  };

  const handleMPUDVersionSelect = (version: string) => {
    navigate(`/MPUDPreview/${version}`, {
      state: {
        S3Location:
          "pasco-ocr-files-dev/MPUD-version_control_files/anjan/Pasco_county/mpud_Pasco_502e0c76-29ba-4692-8f2c-a1d3f199e6bd.pdf",
        username: "anjan",
      },
      replace: true,
    });
  };

  return (
    <div className="flex h-screen">
      <Sidebar
        recentChats={[]}
        onNewChat={handleNewChat}
        onChatSelect={(chatId: string, chatTitle: string) => {
          navigate(`/chat/${chatId}`, { state: { chatTitle: chatTitle } });
        }}
        sendSelectedChatTitle={(chatTitle: string) => {
          navigate(`/chat`, { state: { chatTitle: chatTitle } });
        }}
        onMPUDVersionSelect={handleMPUDVersionSelect}
      />

      <main className="flex-1 overflow-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <Outlet />
        </div>
      </main>
    </div>
  );
}

export default SettingsLayout;
