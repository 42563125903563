import React, { useState, useRef, useEffect } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ChatHeading, ChatInput, ChatMessages } from "@/components/chat";
import { useParams, useLocation, useOutletContext, useSearchParams } from "react-router-dom";
import DocumentPreview from "@/components/chat/DocumentPreview";
import { useCallback } from "react";
import {
  ChatMessage,
  fetchChatHistory,
  NewMessageResponse,
  sendMessage,
  MPUDDocumentRulesData,
} from "@/utils/api";
import { getCookie } from "@/utils/auth";
import { getInitials } from "@/utils/user";
import ImageModal from "@/components/chat/ImageModal";
import { BACKEND_BASE_URL } from "@/utils/api";

const Conversation: React.FC = () => {
  const { chatId } = useParams<{ chatId?: string }>();
  const location = useLocation();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isTyping, setIsTyping] = useState(false);
  const [showDocumentViewer, setShowDocumentViewer] = useState(false);
  const [usernameAvatar, setUsernameAvatar] = useState("-");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const initialMessageProcessed = useRef(false);
  const { activeChatTitle } = useOutletContext<{ activeChatTitle: string }>();
  const [initialDocument, setInitialDocument] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [fileName, setFileName] = useState<string>("");
  const [pageNo, setPageNo] = useState<string>("");
  const [sourceText,setSourceText] = useState<string>("");

  const scrollToBottom = (smooth = true) => {
    if (smooth) messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    else messagesEndRef.current?.scrollIntoView();
  };

  const setChatHistory = useCallback(async () => {
    if (chatId && !location.state?.initialMessage) {
      const history = await fetchChatHistory(chatId);
      setMessages(history.messages);
    }
  }, [chatId, location.state?.initialMessage]);

  const createUsernameAvatar = useCallback(async () => {
    let username = (await getCookie("username")) || (await getCookie("email"));
    if (username) {
      console.log(username);
      username = await getInitials(username);
    } else {
      username = ":)";
    }
    setUsernameAvatar(username);
  }, []);

  useEffect(scrollToBottom, [messages, isTyping]);

  useEffect(() => {
    const initialMessage = location.state?.initialMessage;
    if (initialMessage && chatId) {
      setMessages((prevState) => {
        if (prevState.length > 0 && prevState[0]) {
          const updatedState = [...prevState];
          if (updatedState[0]?.content.ai) {
            updatedState[0].content.ai.message =
              location.state?.responseMessage?.content || "";
          }
          return updatedState;
        } else {
          const newMessage: ChatMessage = {
            chat_id: chatId,
            timestamp: new Date().toISOString(),
            content: {
              human: { message: initialMessage },
              ai: { message: location.state?.responseMessage?.content || "" },
            },
          };
          return [newMessage];
        }
      });
    }
    setIsTyping(location.state?.isTyping || false);
  }, [location.state, chatId]);

  useEffect(() => {
    if (chatId) {
      setChatHistory();
    }
  }, [chatId, setChatHistory]);

  useEffect(() => {
    setChatHistory();
    createUsernameAvatar();
  }, [setChatHistory, createUsernameAvatar]);

  const handleSend = (content: string) => {
    if (!chatId) return;

    const newMessage: ChatMessage = {
      chat_id: chatId,
      timestamp: new Date().toISOString(),
      content: {
        human: { message: content },
        ai: { message: "Typing..." },
      },
    };
    setMessages((prevState) => [...prevState, newMessage]);

    if (content.trim()) {
      const parseMessage = (messageObj: NewMessageResponse) => {
        setMessages((prevState) => {
          const updatedState = [...prevState];
          const lastMessage = updatedState[updatedState.length - 1];
          if (lastMessage && lastMessage.chat_id === messageObj.chat_id) {
            lastMessage.content.ai.message = messageObj.content;
          }
          return updatedState;
        });
      };
      sendMessage(content.trim(), parseMessage, setIsTyping, chatId);
    }
  };

  const onAttach = async () => {
    if (!chatId) return;
    const initialDocument = await MPUDDocumentRulesData(chatId);
    setInitialDocument(initialDocument);
    setShowDocumentViewer(!showDocumentViewer);
  };

  const handleCloseDocumentViewer = () => {
    setShowDocumentViewer(false);
  };


    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const fileNameParam = params.get("file_name") || "";
      const pageNoParam = params.get("page_no") || "";
  
      if (fileNameParam && pageNoParam) {
        setFileName(fileNameParam);
        setPageNo(pageNoParam);
        setIsImageModalOpen(true);
      } else {
        setIsImageModalOpen(false);
      }
    }, [searchParams, location]);

    const handleFileClick = (clickedFileName: string, clickedPageNo: string) => {
      setSearchParams({ file_name: clickedFileName, page_no: clickedPageNo });
    };
  
    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const fileNameParam = params.get("file_name") || "";
      const pageNoParam = params.get("page_no") || "";
  
      if (fileNameParam && pageNoParam) {
        setFileName(fileNameParam);
        setPageNo(pageNoParam);
        setIsImageModalOpen(true);
      } else {
        setIsImageModalOpen(false);
      }
    }, [searchParams, location]);
  
    const handleCloseModal = () => {
      setSearchParams({});
    };
  
    const imageUrl =
      fileName && pageNo
        ? `${BACKEND_BASE_URL}/source/page/${encodeURIComponent(
            fileName
          )}?page_number=${pageNo}`
        : "";
  
    const chatTitle = location.state?.chatTitle
      ? location.state.chatTitle
      : "Untitled chat";
  return (
    <div className="flex justify-center h-screen w-full px-5 rounded-lg overflow-hidden">
      <div
        className={`flex flex-col ${showDocumentViewer ? "w-1/2" : "w-6/12"}`}
      >
        <ChatHeading
          title={
            location.state?.chatTitle
              ? location.state.chatTitle
              : activeChatTitle
                ? activeChatTitle
                : ""
          }
        />
        <ScrollArea className="flex-grow p-4">
          <div className="px-4">
            <ChatMessages messages={messages} username={usernameAvatar} />
            <div ref={messagesEndRef} />
          </div>
        </ScrollArea>
        <ChatInput
          variant={"withAttachment"}
          onSend={handleSend}
          onAttach={onAttach}
          disabled={isTyping}
        />
      </div>
      {showDocumentViewer && (
        <div className="w-1/2 p-4">
          <DocumentPreview
            initialDocument={initialDocument}
            onClose={handleCloseDocumentViewer}
            variant={"default"}
            chatId={chatId}
            chatTitle={chatTitle}
          />
        </div>
      )}
      {/* <ImageModal
        isOpen={isImageModalOpen}
        onClose={handleCloseModal}
        fileName={fileName}
        pageNumber={pageNo}
        sourceText={'Page'}
      /> */}
    </div>
  );
};

export default Conversation;
