import React, { createContext, useState, useEffect } from "react";
import { User } from "@/utils/api";
import { fetchUserData } from "@/utils/user";

interface UserContextType {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  isLoading: boolean;
  getInitials: (username: string) => string;
}

export const UserContext = createContext<UserContextType | undefined>(
  undefined
);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const userData = await fetchUserData();
        setUser(userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadUser();
  }, []);

  const getInitials = (username: string): string => {
    if (typeof username !== "string" || username.trim() === "") {
      return "";
    }

    const nameParts = username.trim().split(/\s+/);

    if (nameParts.length === 0) {
      return "";
    }

    if (nameParts.length === 1) {
      return nameParts[0]?.slice(0, 2).toUpperCase() ?? "";
    }

    const firstInitial = nameParts[0]?.[0] ?? "";
    const lastInitial = nameParts[nameParts.length - 1]?.[0] ?? "";

    return (firstInitial + lastInitial).toUpperCase();
  };

  return (
    <UserContext.Provider value={{ user, setUser, isLoading, getInitials }}>
      {children}
    </UserContext.Provider>
  );
};
