import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
  PaginationLink,
} from "@/components/ui/pagination";
import { Upload, Trash2, Eye } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { fetchFiles, uploadFile, viewFile } from "@/utils/api";
import { toast } from "sonner";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Badge } from "@/components/ui/badge";

// Update the KnowledgeBaseItem type
interface KnowledgeBaseItem {
  file_name: string;
  s3_path: string;
  uploaded_at: string;
  user_id: string;
  user_name: string;
  summary: string;
  category: string;
  zoning: string;
  keywords: string[];
}

const PAGE_SIZE = 5;

const KnowledgeBaseFileUpload: React.FC = () => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [knowledgeBase, setKnowledgeBase] = useState<KnowledgeBaseItem[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [totalFiles, setTotalFiles] = useState<number>(0);
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string>("");
  const [loading, setLoading] = useState(true);

  const fetchKnowledgeBase = useCallback(async () => {
    try {
      const response = await fetchFiles();
      const files: KnowledgeBaseItem[] = response as KnowledgeBaseItem[];
      files.sort(
        (a, b) =>
          new Date(b.uploaded_at).getTime() - new Date(a.uploaded_at).getTime()
      );
      const totalFiles = files.length;
      const totalPages = Math.ceil(totalFiles / PAGE_SIZE);
      const paginatedFiles = files.slice(
        (currentPage - 1) * PAGE_SIZE,
        currentPage * PAGE_SIZE
      );
      return { paginatedFiles, totalFiles, totalPages };
    } catch (error) {
      console.error("Error fetching knowledge base:", error);
      toast.error("Failed to fetch knowledge base");
      throw error;
    }
  }, [currentPage]);

  const reloadKnowledgeBase = useCallback(async () => {
    setLoading(true);
    try {
      const { paginatedFiles, totalFiles, totalPages } =
        await fetchKnowledgeBase();
      setKnowledgeBase(paginatedFiles);
      setTotalFiles(totalFiles);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error reloading knowledge base:", error);
      toast.error("Failed to reload knowledge base");
    } finally {
      setLoading(false);
    }
  }, [fetchKnowledgeBase]);

  useEffect(() => {
    reloadKnowledgeBase();
  }, [currentPage, reloadKnowledgeBase]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      const uniqueNewFiles = newFiles.filter(
        (newFile) =>
          !uploadedFiles.some(
            (existingFile) => existingFile.name === newFile.name
          )
      );

      const duplicateCount = newFiles.length - uniqueNewFiles.length;

      if (duplicateCount > 0) {
        toast.info(
          `${duplicateCount} duplicate file(s) found. Only the first occurrence will be uploaded.`
        );
      }

      if (uniqueNewFiles.length > 0) {
        setUploadedFiles((prevFiles) => [...prevFiles, ...uniqueNewFiles]);
        toast.success(`${uniqueNewFiles.length} file(s) added successfully.`);
      } else if (newFiles.length > 0) {
        toast.warning("All selected files are duplicates and were skipped.");
      }
    }
  };

  const handleDelete = (index: number) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    toast.info("File removed from the upload list.");
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    if (event.dataTransfer.files) {
      const newFiles = Array.from(event.dataTransfer.files);
      const uniqueNewFiles = newFiles.filter(
        (newFile) =>
          !uploadedFiles.some(
            (existingFile) => existingFile.name === newFile.name
          )
      );

      const duplicateCount = newFiles.length - uniqueNewFiles.length;

      if (duplicateCount > 0) {
        toast.info(
          `${duplicateCount} duplicate file(s) found. Only the first occurrence will be uploaded.`
        );
      }

      if (uniqueNewFiles.length > 0) {
        setUploadedFiles((prevFiles) => [...prevFiles, ...uniqueNewFiles]);
        toast.success(`${uniqueNewFiles.length} file(s) added successfully.`);
      } else if (newFiles.length > 0) {
        toast.warning("All dropped files are duplicates and were skipped.");
      }
    }
  };

  const openFileDialog = () => {
    fileInputRef.current?.click();
  };

  const uploadFiles = async () => {
    if (uploadedFiles.length === 0) {
      toast.error("No files to upload");
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);

    const totalFiles = uploadedFiles.length;
    let uploadedCount = 0;

    for (const file of uploadedFiles) {
      try {
        await uploadFile(file);
        uploadedCount++;
        setUploadProgress((uploadedCount / totalFiles) * 100);
      } catch (error) {
        toast.error(`Failed to upload ${file.name}`);
        break;
      }
    }

    setIsUploading(false);
    setUploadedFiles([]);
    setUploadProgress(100);

    if (uploadedCount === totalFiles) {
      toast.success("All files uploaded successfully");
    } else {
      toast.warning(`Uploaded ${uploadedCount} out of ${totalFiles} files`);
    }

    await reloadKnowledgeBase();
  };

  const handleViewClick = async (s3Location: string) => {
    try {
      const toastId = toast.loading("Loading file for preview...");
      const blob = await viewFile(s3Location);
      const blobUrl = URL.createObjectURL(blob);
      toast.success("File loaded successfully", { id: toastId });
      setPreviewUrl(blobUrl);
      setIsPreviewOpen(true);
    } catch (error) {
      console.error("Error viewing file:", error);
      toast.error("Failed to load the file for preview");
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const renderGeneratingOrContent = (
    content: string | string[],
    defaultContent = "Generating.."
  ) => {
    return content &&
      (typeof content === "string" ? content.length > 0 : content.length > 0)
      ? content
      : defaultContent;
  };

  const renderKeywords = (keywords: string[]) => {
    if (!keywords || keywords.length === 0) {
      return <Badge variant="secondary">Generating...</Badge>;
    }

    return (
      <div className="flex flex-wrap gap-1 max-w-[150px] overflow-hidden">
        {keywords.slice(0, 2).map((keyword, idx) => (
          <Badge key={idx} variant="secondary">
            {keyword}
          </Badge>
        ))}
        {keywords.length > 2 && (
          <Badge
            variant="secondary"
            title={keywords
              .map((keyword, idx) => {
                if ((idx + 1) % 3 === 0) {
                  return `${keyword},\n`;
                }
                return `${keyword},`;
              })
              .join("")}
          >
            +{keywords.length - 2}
          </Badge>
        )}
      </div>
    );
  };

  return (
    <div className="container mx-auto p-4">
      <Card className="mb-4">
        <CardHeader>
          <CardTitle>Upload Documents</CardTitle>
        </CardHeader>
        <CardContent>
          <div
            className={`border-2 border-dashed rounded-lg p-8 text-center ${
              isDragging ? "border-blue-500 bg-blue-50" : "border-gray-300"
            }`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={openFileDialog}
          >
            <Upload className="mx-auto h-12 w-12 text-gray-400" />
            <p className="mt-2 text-sm text-gray-600">
              Drag and drop files here, or click to select files
            </p>
            <Input
              ref={fileInputRef}
              type="file"
              onChange={handleFileUpload}
              className="hidden"
              multiple
            />
          </div>
          <div className="mt-4">
            {uploadedFiles.length > 0 && (
              <ul className="list-disc pl-6">
                {uploadedFiles.map((file, index) => (
                  <li key={index} className="flex justify-between items-center">
                    {file.name}
                    <Button
                      variant="ghost"
                      className="text-red-500 hover:text-red-700"
                      onClick={() => handleDelete(index)}
                    >
                      <Trash2 className="h-5 w-5" />
                    </Button>
                  </li>
                ))}
              </ul>
            )}
          </div>

          {isUploading && <Progress value={uploadProgress} className="mt-4" />}

          <Button
            className="mt-4"
            onClick={uploadFiles}
            disabled={uploadedFiles.length === 0 || isUploading}
          >
            {isUploading ? "Uploading..." : "Upload Files"}
          </Button>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Files in Knowledge Base</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="border rounded-md">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[200px]">Uploaded At</TableHead>
                  <TableHead className="w-[150px]">File Name</TableHead>
                  <TableHead className="w-[200px]">S3 Path</TableHead>
                  <TableHead className="w-[100px]">User Name</TableHead>
                  <TableHead className="w-[300px]">Summary</TableHead>
                  <TableHead className="w-[100px]">Category</TableHead>
                  <TableHead className="w-[100px]">Zoning</TableHead>
                  <TableHead className="w-[200px]">Keywords</TableHead>
                  <TableHead className="w-[100px]">Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {loading ? (
                  Array.from({ length: PAGE_SIZE }).map((_, index) => (
                    <TableRow key={index}>
                      <TableCell className="w-[200px]">
                        <div className="h-5 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                      <TableCell className="w-[200px]">
                        <div className="h-5 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                      <TableCell className="w-[200px]">
                        <div className="h-5 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                      <TableCell>
                        <div className="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                      <TableCell className="w-[200px]">
                        <div className="h-5 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                      <TableCell className="w-[200px]">
                        <div className="h-5 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                      <TableCell className="w-[200px]">
                        <div className="h-5 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                      <TableCell>
                        <div className="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                      <TableCell>
                        <div className="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : knowledgeBase.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} className="h-[352px] text-center">
                      No files found
                    </TableCell>
                  </TableRow>
                ) : (
                  knowledgeBase.map((doc, index) => (
                    <TableRow key={index}>
                      <TableCell className="whitespace-nowrap">
                        {formatDate(doc.uploaded_at)}
                      </TableCell>
                      <TableCell
                        className="max-w-[250px] truncate"
                        title={doc.file_name}
                      >
                        {doc.file_name}
                      </TableCell>
                      <TableCell
                        className="max-w-[250px] truncate"
                        title={doc.s3_path}
                      >
                        {doc.s3_path}
                      </TableCell>
                      <TableCell className="min-w-[200px] whitespace-nowrap">
                        {doc.user_name}
                      </TableCell>
                      <TableCell className="min-w-[350px]" title={doc.summary}>
                        {renderGeneratingOrContent(doc.summary)}
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        {renderGeneratingOrContent(doc.category)}
                      </TableCell>
                      <TableCell>
                        {renderGeneratingOrContent(doc.zoning)}
                      </TableCell>
                      <TableCell>{renderKeywords(doc.keywords)}</TableCell>
                      <TableCell>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleViewClick(doc.s3_path)}
                        >
                          <Eye className="h-4 w-4 mr-2" />
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
          <Pagination>
            <PaginationContent className="mt-4 flex justify-center">
              <PaginationPrevious
                className={`mr-4 ${currentPage === 1 ? "cursor-not-allowed opacity-50" : ""}`}
                onClick={currentPage === 1 ? undefined : handlePreviousPage} // Disable click if on the first page
              >
                Previous
              </PaginationPrevious>

              <PaginationItem>
                <PaginationLink>{currentPage}</PaginationLink>
              </PaginationItem>

              <PaginationNext
                className={`ml-4 ${currentPage === totalPages ? "cursor-not-allowed opacity-50" : ""}`}
                onClick={
                  currentPage === totalPages ? undefined : handleNextPage
                } // Disable click if on the last page
              >
                Next
              </PaginationNext>
            </PaginationContent>
          </Pagination>
        </CardContent>
      </Card>
      <Dialog open={isPreviewOpen} onOpenChange={setIsPreviewOpen}>
        <DialogContent className="max-w-7xl w-11/12 max-h-[95vh] p-0">
          <DialogHeader className="p-4 pb-0">
            <DialogTitle>File Preview</DialogTitle>
          </DialogHeader>
          <div className="flex-1 w-full h-[calc(95vh-3rem)]">
            <iframe
              src={previewUrl}
              className="w-full h-full border-none"
              title="PDF Preview"
            />
          </div>
        </DialogContent>
      </Dialog>
      <div className="mt-2 text-sm text-gray-500">
        Total Files: {totalFiles}
      </div>
    </div>
  );
};

export default KnowledgeBaseFileUpload;
