"use client";
import React, { useState, useEffect } from 'react';

interface TypingEffectProps {
  text: string;
  onComplete?: () => void;
}

const TypingEffect: React.FC<TypingEffectProps> = ({ text, onComplete }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      setDisplayedText((prev) => text.substring(0, index + 1));
      index++;
      if (index === text.length) {
        clearInterval(interval);
        if(onComplete){
            onComplete();
        }
      }
    }, 15); // Adjust typing speed by changing the interval duration

    return () => clearInterval(interval);
  }, [text]);

  return <div className='text-gray-500 text-base md:w-max break-words ' style={{ margin: 0,maxWidth:'500px' }} >{displayedText}</div>;
};

export default TypingEffect;