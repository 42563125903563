import React, { useState, useEffect } from "react";
import { ChatInput } from "@/components/chat";
import { useNavigate } from "react-router-dom";
import { fetchUserData, getCookie } from "@/utils/auth";
import { NewMessageResponse, sendMessage } from "@/utils/api";

interface Messages {
  content: string;
  sender: string;
}

const Chat = () => {
  const [greeting, setGreeting] = useState("");
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>("");
  const [isAITyping, setIsAITyping] = useState(false);
  let activeChatId = "";

  const greetings = [
    { startHour: 0, endHour: 5, message: "Happy, late night! " },
    { startHour: 5, endHour: 12, message: "Good morning! " },
    { startHour: 12, endHour: 17, message: "Good afternoon! " },
    { startHour: 17, endHour: 21, message: "Good evening! " },
    { startHour: 21, endHour: 24, message: "Good night! " },
  ];

  const getGreeting = () => {
    const hour = new Date().getHours();
    const currentGreeting = greetings.find(
      (g) => hour >= g.startHour && hour < g.endHour
    );
    return currentGreeting ? currentGreeting.message : "Hello!";
  };

  const handleSend = (content: string) => {
    let navigated = false;
    let chatTitle = "";
    if (content.trim()) {
      const parseMessage = (messageObj: NewMessageResponse) => {
        if (navigated) {
          navigate(`/chat/${messageObj.chat_id}`, {
            replace: true,
            state: {
              initialMessage: content.trim(),
              responseMessage: messageObj,
              isTyping: true,
              chatTitle: messageObj.chat_title,
            },
          });
        } else {
          navigate(`/chat/${messageObj.chat_id}`, {
            state: {
              initialMessage: content.trim(),
              responseMessage: messageObj,
              isTyping: true,
              chatTitle: messageObj.chat_title,
            },
          });
          navigated = true;
          chatTitle = messageObj.chat_title;
          activeChatId = messageObj.chat_id;
        }
      };
      const setTypingState = (isTyping: boolean) => {
        setIsAITyping(isTyping);
        if (activeChatId) {
          navigate(`/chat/${activeChatId}`, {
            replace: true,
            state: { isTyping: isTyping, chatTitle: chatTitle },
          });
        }
      };
      sendMessage(content.trim(), parseMessage, setTypingState);
    }
  };

  useEffect(() => {
    const load = async () => {
      try {
        const value = await getCookie("email");
        if (value == undefined) throw "error";
        const userData = await fetchUserData();
        if (userData) {
          setUsername(userData.name);
        }
      } catch (e) {
        navigate("/login");
      }
    };
    load();
  }, []);

  useEffect(() => {
    setGreeting(getGreeting());
  }, []);

  return (
    <div className="max-w-3xl mx-auto p-6 space-y-6">
      {/* Chat Area */}
      <div className="flex-1 flex flex-col">
        <div className="p-4 bg-white">
          <h1 className="text-2xl text-center font-bold">
            {greeting} {username}
          </h1>
        </div>
        <div className="p-4 bg-white">
          <div className="flex justify-center items-center">
            <ChatInput
              className="w-[60ch]"
              variant={"rounded"}
              size={"tall"}
              onSend={handleSend}
              disabled={isAITyping}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
